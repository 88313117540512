const FKP_IMAGE_CONTAINER_CLASS = 'fkp__image-container';

export default class ImageContainer {
  html = document.createElement('div');
  picture = document.createElement('picture');
  img = document.createElement('img');

  /**
   *
   * @param {Object} props the properties object for the image
   * @param {Object[]} props.sources An array of source objects. Each object can have type and srcset.
   * @param {string} props.src Specifies the fallback source URL for the image.
   * @param {string} [props.alt] (Optional) Specifies alternate text for the image.
   * @param {string} [props.height] (Optional) Specifies height for the image.
   * @param {string} [props.loading] (Optional) Lazy loading. Eager or Lazy.
   * @param {string[]} [props.classes] (Optional) Specifies the classes for the image element.
   */
  constructor(props) {
    this.props = props;
    this.initializeContainer();
    this.initializeSources();
    this.initializeImage();
  }

  initializeContainer = () => {
    this.html.classList.add(FKP_IMAGE_CONTAINER_CLASS);
    if (this.props.classes) {
      this.html.classList.add(...this.props.classes);
    }
    this.html.appendChild(this.picture);
  };

  initializeSources = () => {
    this.props.sources.forEach((sourceObj) => {
      const sourceElement = document.createElement('source');
      if (sourceObj.type) {
        sourceElement.type = sourceObj.type;
      }
      if (sourceObj.srcset) {
        sourceElement.srcset = sourceObj.srcset;
      }
      this.picture.appendChild(sourceElement);
    });
  };

  initializeImage = () => {
    this.picture.appendChild(this.img);
    this.img.src = this.props.src;

    const { alt = null, height = null, loading = null, width = null } = this.props;
    if (alt) {
      this.img.alt = alt;
    }
    if (height) {
      this.img.height = height;
    }
    if (width) {
      this.img.width = width;
    }
    if (loading) {
      this.img.loading = loading;
    }
  };
}
