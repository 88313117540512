import Notice from '../../Notice';
import { FORM_FIELD_CLASSES } from '../formConstants';

const FKP_ONE_TIME_CODE_FIELD_CLASS = 'field--one-time-code';

export default class OTCInput {
  html = document.createElement('div');
  wrapper = document.createElement('div');
  input = document.createElement('input');
  errorField = null;

  props = {
    required: true,
    autofocus: true,
    pattern: /\d{6}/,
    digits: 6,
    name: 'verification_code',
    id: 'fkp__verification_code',
    errorId: 'error-for-verification_code',
    errorText: 'Enter a valid verification code',
  };

  constructor(props) {
    this.props = { ...this.props, ...props };
    this.initialize();
  }

  initialize = () => {
    this.constructDOM();
    this.attachEventListeners();
  };

  reportValidity = () => {
    let valid = true;
    const { required, pattern } = this.props;
    const { value } = this.input;
    if (required && !value) {
      valid = false;
    }
    if (valid && pattern && !pattern.test(this.input.value)) {
      valid = false;
    }
    valid ? this.hideError() : this.showError();
    return valid;
  };

  /**
   * Displays the error text for this input field.
   */
  showError = () => {
    const { errorId, errorText } = this.props;
    if (errorId) {
      this.input.setAttribute('aria-describedby', errorId);
    }
    this.input.setAttribute('aria-invalid', 'true');

    this.html.classList.add(FORM_FIELD_CLASSES.ERROR);
    this.errorField.showError(errorText);
    this.clear();
  };

  /**
   * Hides the error text for this input field.
   */
  hideError = () => {
    this.input.removeAttribute('aria-describedby');
    this.input.removeAttribute('aria-invalid');
    this.html.classList.remove(FORM_FIELD_CLASSES.ERROR);
    this.errorField.clear();
  };

  getFirstSubInput = () => {
    return this.wrapper.querySelector('[data-index="0"]');
  };

  focus = () => {
    this.getFirstSubInput()?.select();
  };

  clear = () => {
    const subInputs = this.wrapper.querySelectorAll('[data-index]');
    [...subInputs].forEach((subInput, index) => {
      if (index === 0) {
        subInput.focus();
      }
      subInput.value = '';
    });
    this.coalesceInputValues();
  };

  constructDOM = () => {
    this.initializeField();
    this.initializeWrapper();
    this.initializeInput();
    this.initializeSubInputs();
    this.initializeErrorField();
  };

  initializeField = () => {
    const { classList } = this.html;
    classList.add(FORM_FIELD_CLASSES.FIELD, FKP_ONE_TIME_CODE_FIELD_CLASS);
  };

  initializeWrapper = () => {
    this.html.appendChild(this.wrapper);
    const { classList } = this.wrapper;
    classList.add(FORM_FIELD_CLASSES.WRAPPER);

    this.wrapper.addEventListener('keydown', (event) => {
      const { key, target } = event;
      if (key !== 'Enter') {
        return;
      }
      event.preventDefault();

      const form = target.form;
      if (!form) {
        return;
      }

      const submitButton = form.querySelector('[type="submit"]');
      if (submitButton) {
        form.requestSubmit(submitButton);
      } else {
        form.requestSubmit();
      }
    });
  };

  initializeInput = () => {
    this.input.ref = this;
    this.wrapper.appendChild(this.input);
    this.input.name = this.props.name;
    this.input.type = 'hidden';
  };

  initializeSubInputs = () => {
    for (let i = 0; i < this.props.digits; i++) {
      this.wrapper.appendChild(this.createSubInput(i));
    }
  };

  initializeErrorField = () => {
    this.errorField = new Notice(this.props);
    this.errorField.setText('');
    this.html.append(this.errorField.html);
  };

  createSubInput = (index) => {
    const input = document.createElement('input');
    input.ref = this;
    input.dataset.index = index;
    input.type = 'text';
    input.size = '1';
    input.autocomplete = index === 0 ? 'one-time-code' : 'off';
    input.autocorrect = 'off';
    input.maxLength = this.props.digits - index;
    input.inputMode = 'numeric';
    input.ariaLabel = `Verification code digit ${index + 1}`;
    input.ariaDescribedBy = this.props.errorId;
    if (this.props.required) {
      input.ariaRequired = 'true';
    }
    if (index === 0 && this.props.autofocus) {
      input.toggleAttribute('autofocus', true);
    }
    input.pattern = '\\d*';
    input.classList.add('field__input');
    return input;
  };

  attachEventListeners = () => {
    const listeners = Object.entries({
      keydown: this.onKeyDown,
      keyup: this.onKeyUp,
      click: this.onClick,
      input: this.onInput,
    });
    for (const [type, listener] of listeners) {
      this.html.addEventListener(type, listener);
    }
  };

  getPreviousChildInput = (element) => {
    const index = parseInt(element.dataset.index);
    return this.wrapper.querySelector(`input[data-index="${index - 1}"]`);
  };

  getNextChildInput = (element) => {
    const index = parseInt(element.dataset.index);
    return this.wrapper.querySelector(`input[data-index="${index + 1}"]`);
  };

  onKeyDown = (event) => {
    this.onKeyUp(event, true);
  };

  onKeyUp = (event, forced) => {
    if (forced) {
      const { target, key } = event;
      const { value } = target;
      const prevInput = this.getPreviousChildInput(target);
      const nextInput = this.getNextChildInput(target);

      switch (key) {
        case 'Backspace':
          if (!value && prevInput) {
            prevInput.focus();
          }
          break;
        case 'ArrowUp':
        case 'ArrowLeft':
          if (prevInput) {
            setTimeout(() => prevInput.select());
          }
          event.preventDefault();
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          if (nextInput) {
            setTimeout(() => nextInput.select());
          }
          event.preventDefault();
          break;
      }
    } else {
      event.preventDefault();
    }
  };

  onClick = (event) => {
    const { target } = event;
    if (target instanceof HTMLInputElement) {
      target.select();
    }
  };

  onInput = (event) => {
    const target = event.target;
    const next = this.getNextChildInput(target);
    const value = target.value;
    const cleanedValue = value.replace(/\D/g, '');

    target.value = cleanedValue.slice(0, 1);
    if (cleanedValue?.length > 0 && next) {
      next.focus();
      next.value = cleanedValue.slice(1);
      next.dispatchEvent(new Event('input', { bubbles: true }));
    }
    this.coalesceInputValues();
  };

  coalesceInputValues = () => {
    const subInputs = this.wrapper.querySelectorAll('[data-index]');
    const digits = [];
    subInputs.forEach((subInput) => {
      const index = parseInt(subInput.dataset.index);
      digits[index] = subInput.value;
    });
    this.input.value = digits.join('');
  };
}
