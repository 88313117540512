const TEST_KEY = 'LOCAL_STORAGE_AVAILABILITY_TEST';

/**
 * @property static {boolean} supported
 */
export default class LocalStorageService {
  static supported = LocalStorageService._isSupported();

  static _isSupported() {
    try {
      const storage = window.localStorage;
      storage.setItem(TEST_KEY, TEST_KEY);
      storage.removeItem(TEST_KEY);
      return true;
    } catch (error) {
      console.error('localStorage is not supported!', error);
      return false;
    }
  }

  static save(key, data) {
    if (!LocalStorageService.supported) {
      console.error('LocalStorageService not available, could not save data');
      return;
    }
    window.localStorage.setItem(key, JSON.stringify(data));
  }

  static load(key) {
    if (!LocalStorageService.supported) {
      console.error('LocalStorageService not available, could not load data');
      return null;
    }
    const data = window.localStorage.getItem(key);
    return JSON.parse(data);
  }
}
