import { EmailText, KratomSampleContainer, OrderNumberText, StageHeader } from '../components/form/field/fields.js';
import PopupContainer from '../components/form/PopupContainer.js';
import Container from '../components/Container.js';
import { Stage6aForm } from '../forms';
import Stage from './Stage.js';
import FormContainer from '../components/FormContainer';
import { FKP_OFFER_DETAILS_CLASS, FKP_STAGE_CLASS, FKP_STAGE_CONTENT_CLASS } from './stageConstants';

const FKP_ORDER_DETAILS_CONTAINER_CLASS = 'fkp__order__details-container';
const HH_ORDER_PREFIX = '#HH';

export default class Stage6a extends Stage {
  static STAGE_CLASS_NAME = 'stage-6a';
  static STAGE_NAME = 'STAGE_6A';

  constructor(popup) {
    super({
      popup: popup,
      stageName: Stage6a.STAGE_NAME,
      formConstructor: Stage6aForm,
    });
    this.orderEmailContainer = null;
    this.updateText = this.updateText.bind(this);
  }

  buildStageContainer(form) {
    const children = this.buildChildren(form);
    const stageContainer = new PopupContainer(this.popup, {
      classes: [Stage6a.STAGE_CLASS_NAME],
      children: children,
      onShow: form?.props?.onShow,
      onLoad: this.handleLoad.bind(this, form),
      hideOnLoad: this.hideOnLoad,
    });
    return stageContainer;
  }

  buildChildren(form) {
    const props = {
      assetUrl: this.popup.popupAssetUrl,
    };
    const stageContent = this.buildStageContent(form);
    const kratomSampleContainer = KratomSampleContainer(props);
    const innerStageContainer = new Container({
      classes: [FKP_STAGE_CLASS, FKP_OFFER_DETAILS_CLASS],
      children: [kratomSampleContainer, stageContent],
    });

    return [innerStageContainer];
  }

  buildStageContent(form) {
    this.orderEmailContainer = this.buildOrderEmailContainer();
    const { title } = this.getStageTitleAndDescription();
    const formContainer = new FormContainer(form);
    const stageInformation = StageHeader(title);
    const stageContent = new Container({
      classes: [FKP_STAGE_CONTENT_CLASS],
      children: [stageInformation, this.orderEmailContainer, formContainer],
    });
    return stageContent;
  }

  buildOrderEmailContainer() {
    const orderNumberText = this.buildOrderNumberText();
    const emailText = this.buildEmailText();
    const mainTextContainer = new Container({
      classes: [FKP_ORDER_DETAILS_CONTAINER_CLASS],
      children: [orderNumberText, emailText],
    });

    this.setupUpdateEvent(mainTextContainer, orderNumberText, emailText);
    return mainTextContainer;
  }

  buildOrderNumberText() {
    const { order_number: orderNumber } = this.popup.getOrder();
    return OrderNumberText(orderNumber);
  }

  buildEmailText() {
    const email = this.popup.getEmail();
    return EmailText(email);
  }

  setupUpdateEvent(container, orderNumberText, emailText) {
    container?.addEventListener?.('update', () => {
      this.updateText(orderNumberText, emailText);
    });
  }

  updateText(orderNumberText, emailText) {
    const { order_number: orderNumber = '' } = this.popup?.getOrder?.() ?? {};
    const email = this.popup?.getEmail?.();
    let fullOrderNumber = orderNumber;
    if (fullOrderNumber && fullOrderNumber !== '') {
      fullOrderNumber = HH_ORDER_PREFIX + fullOrderNumber;
    }
    orderNumberText?.setText?.(`${fullOrderNumber}`);
    emailText?.setText?.(`${email}`);
  }

  handleLoad(form, popupContainer) {
    form?.props?.onLoad?.(popupContainer);
    this.orderEmailContainer?.dispatchEvent?.('update');
    this.popup.toggleFullSize(true);
  }
}
