import * as Sentry from '@sentry/browser';

export class SentryUtils {
  static init() {
    try {
      SentryUtils._initImpl();
    } catch (err) {
      console.error(err);
    }
  }

  static _initImpl() {
    Sentry.init({
      dsn: 'https://50cf09f61396af104e56bdbbdadc9156@o1240698.ingest.us.sentry.io/4508042122100736',
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: 1.0,
      tracePropagationTargets: [/free-kratom-api.happyhippo.com/],
    });
  }
}
