const NAMESPACE = 'http://www.w3.org/2000/svg';
const DEFAULT_VIEWBOX = '0 0 48 48';

export default class IconUtils {
  static buildSvg(pathData, viewBox = DEFAULT_VIEWBOX) {
    const svg = document.createElementNS(NAMESPACE, 'svg');
    const path = document.createElementNS(NAMESPACE, 'path');

    svg.setAttribute('viewBox', viewBox);
    path.setAttribute('d', pathData);
    svg.appendChild(path);

    svg.setAttribute('aria-hidden', 'true');
    svg.setAttribute('role', 'presentation');
    svg.setAttribute('focusable', 'false');
    svg.classList.add('icon');
    return svg;
  }
}
