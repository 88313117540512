import { KratomSampleContainer, StageHeader } from '../components/form/field/fields.js';
import PopupContainer from '../components/form/PopupContainer.js';
import Container from '../components/Container.js';
import { Stage4aForm } from '../forms';
import Stage from './Stage.js';
import { FORM_KEY_EMAIL, INPUT_CLASS } from '../constants.js';
import FormContainer from '../components/FormContainer';
import { parseAddressFromFormData } from '../utils';
import { FKP_OFFER_DETAILS_CLASS, FKP_STAGE_CLASS, FKP_STAGE_CONTENT_CLASS } from './stageConstants';

export default class Stage4a extends Stage {
  static STAGE_CLASS_NAME = 'stage-4a';
  static STAGE_NAME = 'STAGE_4A';

  constructor(popup) {
    super({
      popup: popup,
      stageName: Stage4a.STAGE_NAME,
      formConstructor: Stage4aForm,
    });
  }

  buildStageContainer(form) {
    const children = this.buildChildren(form);
    const stageContainer = new PopupContainer(this.popup, {
      classes: [Stage4a.STAGE_CLASS_NAME],
      children: children,
      onShow: form?.props?.onShow,
      onLoad: this.handleLoad.bind(this, form),
      hideOnLoad: this.hideOnLoad,
    });
    return stageContainer;
  }

  buildChildren(form) {
    const props = {
      assetUrl: this.popup.popupAssetUrl,
    };
    const stageContent = this.buildStageContent(form);
    const kratomSampleContainer = KratomSampleContainer(props);
    const innerStageContainer = new Container({
      classes: [FKP_STAGE_CLASS, FKP_OFFER_DETAILS_CLASS],
      children: [kratomSampleContainer, stageContent],
    });
    return [innerStageContainer];
  }

  buildStageContent(form) {
    const { title } = this.getStageTitleAndDescription();
    const formContainer = new FormContainer(form);

    const formElement = form.html;
    this.addFormBlurListener(formElement);
    this.restoreFormAddressValues(form.html);

    const stageInformation = StageHeader(title);
    const stageContent = new Container({
      classes: [FKP_STAGE_CONTENT_CLASS],
      children: [stageInformation, formContainer],
    });
    return stageContent;
  }

  addFormBlurListener(formElement) {
    formElement.addEventListener('blur', () => this.updateStateFromFormElement(formElement), true);
  }

  updateStateFromFormElement(formElement) {
    const formData = new FormData(formElement);
    const email = formData.get(FORM_KEY_EMAIL);
    const address = parseAddressFromFormData(formData);
    this.popup.setAddress(address);
    this.popup.setEmail(email);
  }

  restoreFormAddressValues(formElement) {
    if (!formElement) {
      return;
    }

    const savedData = {
      ...this.popup.getAddress(),
      email_address: this.popup.getEmail(),
    };
    setInputValuesFromSavedData(formElement, savedData);
  }

  handleLoad(form, popupContainer) {
    form?.props?.onLoad?.(popupContainer);
    this.popup.toggleFullSize(true);
  }
}

function setInputValuesFromSavedData(formElement, savedData) {
  const inputElements = formElement.querySelectorAll(`.${INPUT_CLASS}`);
  inputElements.forEach((input) => {
    const addressName = extractNameFromAddress(input.name);
    const savedValue = savedData[addressName];
    if (savedValue) {
      input.value = savedValue;
    }
  });
}

function extractNameFromAddress(addressName) {
  const startIndex = addressName.indexOf('[') + 1;
  const endIndex = addressName.indexOf(']');
  if (startIndex === -1 || endIndex === -1) {
    return addressName;
  }
  return addressName.substring(startIndex, endIndex);
}
