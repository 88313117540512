import Container from '../../Container';
import { PasswordInput, VerifyPasswordInput } from './fields';

const FORM_KEY_CREATE_CUSTOMER_PASSWORD = 'customer[password]';

export default class PasswordContainer extends Container {
  constructor(props) {
    const passwordInput = PasswordInput(FORM_KEY_CREATE_CUSTOMER_PASSWORD);
    const verifyPasswordInput = VerifyPasswordInput();
    const children = [passwordInput, verifyPasswordInput];
    super({ ...props, children });

    this.passwordInput = passwordInput;
    this.verifyPasswordInput = verifyPasswordInput;

    this.addEventListeners();
    this.addVerifyPasswordValidation();
  }

  addEventListeners = () => {
    const inputs = [this.verifyPasswordInput.input.html, this.passwordInput.input.html];
    for (const inputElement of inputs) {
      inputElement.addEventListener('blur', () => {
        this.onBlur();
      });
    }
  };

  addVerifyPasswordValidation = () => {
    const formFieldInput = this.verifyPasswordInput;
    const verifyPasswordInputOriginalReportValidity = formFieldInput.reportValidity;
    formFieldInput.reportValidity = () => {
      try {
        this.verifyPasswordMatch();
      } catch (error) {
        formFieldInput.showError(error);
        return false;
      }

      const originalResult = verifyPasswordInputOriginalReportValidity.apply(this);
      return originalResult;
    };
  };

  verifyPasswordMatch = () => {
    const pass = this.passwordInput.input.html.value;
    const verifyPass = this.verifyPasswordInput.input.html.value;

    if (pass === '' || verifyPass === '') {
      return false;
    }

    if (pass !== verifyPass) {
      throw new Error('Passwords must match');
    }

    return true;
  };

  onBlur = () => {
    try {
      const passwordsMatch = this.verifyPasswordMatch();
      if (passwordsMatch) {
        this.verifyPasswordInput.hideError();
      }
    } catch (error) {
      this.verifyPasswordInput.showError(error);
    }
  };
}
