export default class SmsDisclaimerParagraph {
  constructor() {
    this.html = this.createElement();
  }

  createElement() {
    const paragraph = document.createElement('p');
    const DISCLAIMER_TEXT_CLASS = 'disclaimer-text';
    paragraph.classList.add(DISCLAIMER_TEXT_CLASS);

    const disclaimerText = this.createDisclaimerText();
    paragraph.appendChild(disclaimerText);

    const termsLink = this.createTermsLink();
    const privacyLink = this.createPrivacyLink();

    paragraph.appendChild(termsLink);
    paragraph.appendChild(document.createTextNode(' & '));
    paragraph.appendChild(privacyLink);

    return paragraph;
  }

  createDisclaimerText() {
    const DISCLAIMER_TEXT =
      'By entering your phone number, you agree to receive automated marketing texts from Happy Hippo. No purchase is required. Reply HELP for help; STOP to opt out. Message and data rates may apply, messaging frequency may vary. ';
    const textNode = document.createTextNode(DISCLAIMER_TEXT);
    return textNode;
  }

  createTermsLink() {
    const TERMS_OF_SERVICE_LINK = 'https://happyhippo.com/policies/terms-of-service';
    const TERMS_TEXT = 'View Terms';
    const termsLink = this.createLink(TERMS_OF_SERVICE_LINK, TERMS_TEXT);
    return termsLink;
  }

  createPrivacyLink() {
    const PRIVACY_POLICY_LINK = 'https://happyhippo.com/policies/privacy-policy';
    const PRIVACY_POLICY_TEXT = 'Privacy';
    const privacyLink = this.createLink(PRIVACY_POLICY_LINK, PRIVACY_POLICY_TEXT);
    return privacyLink;
  }

  createLink(href, text) {
    const link = document.createElement('a');
    link.href = href;
    link.textContent = text;
    return link;
  }
}
