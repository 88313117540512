import StageForm from './StageForm';
import { createFormContainerWithChildren, injectSessionInFormData, parseAddressFromFormData } from '../utils.js';
import StreetAutoCompleteComboBox from '../components/form/field/extensions/StreetAutoComplete.js';
import {
  AddressLine1Input,
  AddressLine2Input,
  CityInput,
  CountryRegionSelect,
  EmailAddressInput,
  FullNameInputContainer,
  PostalCodeInput,
  StateProvinceSelect,
  SubmitButton,
} from '../components/form/field/fields.js';
import { FKS_ENDPOINT_ADDRESS_VERIFY, FORM_KEY_EMAIL, STAGES } from '../constants.js';
import { HTTP_METHOD_POST } from './stageFormConstants';

const COUNTRY_CODES = {
  US: 'US',
};
const FKP_ADDRESS_EMAIL_CLASS = 'fkp__address-email';

export default class Stage4aForm extends StageForm {
  constructor(popup = {}) {
    const urlDetails = {
      baseUrl: popup.apiBaseUrl,
      action: FKS_ENDPOINT_ADDRESS_VERIFY,
      method: HTTP_METHOD_POST,
    };
    const formOptions = {
      noValidate: true,
    };
    const formProps = {
      popup: popup,
      urlDetails: urlDetails,
      formOptions: formOptions,
    };
    super(formProps);
    this.children = this.buildChildren(popup);
  }

  buildChildren(popup) {
    const nameContainer = FullNameInputContainer();
    const address1 = AddressLine1Input();
    const address2 = AddressLine2Input();
    const city = CityInput();
    const country = CountryRegionSelect();
    const province = StateProvinceSelect(COUNTRY_CODES.US);
    const postalCode = PostalCodeInput();
    const email = EmailAddressInput(FKP_ADDRESS_EMAIL_CLASS);
    const submitButton = SubmitButton();

    const zipCountryContainer = createFormContainerWithChildren([postalCode, country]);
    const cityStateContainer = createFormContainerWithChildren([city, province]);

    new StreetAutoCompleteComboBox(popup, {
      address1: address1.html,
      city: city.html,
      country: country.html,
      province: province.html,
      postalCode: postalCode.html,
    });

    return [
      nameContainer.html,
      address1.html,
      address2.html,
      cityStateContainer.html,
      zipCountryContainer.html,
      email.html,
      submitButton.html,
    ];
  }

  updateStateFromFormData = (formData) => {
    const email = formData.get(FORM_KEY_EMAIL);
    const address = parseAddressFromFormData(formData);
    this.popup.setAddress(address);
    this.popup.setEmail(email);
  };

  beforeSubmit(popupForm) {
    const { formData } = popupForm;
    injectSessionInFormData(this.popup.getSessionUuid(), formData);
  }

  onSuccess(data, popupForm) {
    const { formData } = popupForm;
    this.updateStateFromFormData(formData);
    this.popup.setStage(STAGES.STAGE_5A);
  }
}
