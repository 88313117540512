import StageForm from './StageForm.js';
import { injectSessionInFormData } from '../utils.js';
import { ClaimTypeInput, DiscountOrSamplesOptionContainer } from '../components/form/field/fields.js';
import { FKP_EVENT, FKS_ENDPOINT_CLAIM_TYPE_SELECT } from '../constants.js';
import { HTTP_METHOD_POST } from './stageFormConstants';

const FORM_KEY_CLAIM_TYPE = 'claim_type';

export default class Stage3Form extends StageForm {
  constructor(popup = {}) {
    const urlDetails = {
      baseUrl: popup.apiBaseUrl,
      action: FKS_ENDPOINT_CLAIM_TYPE_SELECT,
      method: HTTP_METHOD_POST,
    };
    const formOptions = {
      noValidate: true,
    };
    const formProps = {
      popup: popup,
      urlDetails: urlDetails,
      formOptions: formOptions,
    };
    super(formProps);
    this.submitInput = ClaimTypeInput();
    this.children = this.buildChildren();
  }

  buildChildren() {
    const props = {
      assetUrl: this.popup.popupAssetUrl,
    };
    const discountOrSamplesContainer = DiscountOrSamplesOptionContainer(props);
    this.addClaimTypeListener(discountOrSamplesContainer);
    return [this.submitInput?.input?.html, discountOrSamplesContainer.html];
  }

  addClaimTypeListener(element) {
    element?.addEventListener?.(FKP_EVENT.SELECT_CLAIM_TYPE, (event) => {
      this.submitInput?.setValue?.(event?.detail);
    });
  }

  beforeSubmit(popupForm) {
    const { formData } = popupForm;
    injectSessionInFormData(this.popup.getSessionUuid(), formData);
  }

  onSuccess(data, popupForm) {
    const { formData } = popupForm;
    const claimType = formData.get(FORM_KEY_CLAIM_TYPE);
    this.popup?.setClaimType(claimType);
    this.popup?.setStageFromClaimType();
  }
}
