import IconUtils from './IconUtils';

export default function IconGiftbox() {
  const viewBox = '0 0 72.722 72.722';
  const pathData = `M66.137,18.801h-3.188h-4.962c2.904-1.824,4.811-4.823,4.811-8.214c0-5.549-5.088-10.063-11.341-10.063
		c-5.306,0-11.589,9.093-15.096,14.97c-3.507-5.876-9.792-14.97-15.097-14.97c-6.253,0-11.341,4.515-11.341,10.063
		c0,3.391,1.906,6.39,4.811,8.214H10.26H6.586C2.955,18.801,0,21.755,0,25.385v10.439c0,2.586,1.502,4.821,3.676,5.897v23.89
		c0,3.632,2.954,6.586,6.584,6.586h52.688c3.632,0,6.586-2.954,6.586-6.586V41.456c1.907-1.154,3.188-3.242,3.188-5.63v-10.44
		C72.723,21.756,69.769,18.801,66.137,18.801z M6.586,36.409C6.263,36.409,6,36.147,6,35.825v-10.44
		c0-0.322,0.263-0.584,0.586-0.584H33.36v11.607L6.586,36.409L6.586,36.409z M39.361,24.801h26.776c0.323,0,0.586,0.262,0.586,0.584
		v10.439c0,0.322-0.263,0.584-0.586,0.584H39.361V24.801z M51.456,6.524c2.895,0,5.341,1.861,5.341,4.063s-2.446,4.063-5.341,4.063
		h-7.41C47.081,10.267,50.199,6.784,51.456,6.524z M21.181,6.519c1.32,0.264,4.454,3.749,7.493,8.133h-7.41
		c-2.895,0-5.341-1.861-5.341-4.063S18.369,6.524,21.181,6.519z M9.676,65.613V42.409h23.685v23.789H10.26
		C9.938,66.199,9.676,65.936,9.676,65.613z M62.949,66.199H39.361V42.409h24.174v23.203C63.535,65.936,63.272,66.199,62.949,66.199z`;
  return IconUtils.buildSvg(pathData, viewBox);
}
