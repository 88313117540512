import { FORM_FIELD_CLASSES, FORM_FIELD_ELEMENT_TYPES } from '../formConstants';

export default class FormFieldWrapper {
  html = null;

  constructor(props) {
    this.create(props);
  }

  create = (props) => {
    this.html = document.createElement(FORM_FIELD_ELEMENT_TYPES.WRAPPER);
    this.html.classList.add(FORM_FIELD_CLASSES.WRAPPER);
    if (props.type === 'select') {
      this.html.classList.add(FORM_FIELD_CLASSES.WRAPPER_SELECT);
    }
  };
}
