import { KratomDiscountContainer, StageHeader } from '../components/form/field/fields.js';
import PopupContainer from '../components/form/PopupContainer.js';
import Container from '../components/Container.js';
import { Stage6bForm } from '../forms';
import Stage from './Stage.js';
import FormContainer from '../components/FormContainer';
import { FKP_OFFER_DETAILS_CLASS, FKP_STAGE_CLASS, FKP_STAGE_CONTENT_CLASS } from './stageConstants';

export default class Stage6b extends Stage {
  static STAGE_CLASS_NAME = 'stage-6b';
  static STAGE_NAME = 'STAGE_6B';

  constructor(popup) {
    super({
      popup: popup,
      stageName: Stage6b.STAGE_NAME,
      formConstructor: Stage6bForm,
    });
  }

  buildStageContainer(form) {
    const children = this.buildChildren(form);
    const stageContainer = new PopupContainer(this.popup, {
      classes: [Stage6b.STAGE_CLASS_NAME],
      children: children,
      onShow: form?.props?.onShow,
      onLoad: this.handleLoad.bind(this, form),
      hideOnLoad: this.hideOnLoad,
    });
    return stageContainer;
  }

  buildChildren(form) {
    const props = {
      assetUrl: this.popup.popupAssetUrl,
    };
    const stageContent = this.buildStageContent(form);
    const kratomDiscountContainer = KratomDiscountContainer(props);
    const innerStageContainer = new Container({
      classes: [FKP_STAGE_CLASS, FKP_OFFER_DETAILS_CLASS],
      children: [kratomDiscountContainer, stageContent],
    });
    return [innerStageContainer];
  }

  buildStageContent(form) {
    const { title } = this.getStageTitleAndDescription();
    const formContainer = new FormContainer(form);
    const stageInformation = StageHeader(title);
    const stageContent = new Container({
      classes: [FKP_STAGE_CONTENT_CLASS],
      children: [stageInformation, formContainer],
    });
    return stageContent;
  }

  handleLoad(form, popupContainer) {
    form?.props?.onLoad?.(popupContainer);
    this.popup.toggleFullSize(true);
  }
}
