import { FKP_BUTTON_CLASS } from '../../../constants';

export default class FormButton {
  html = document.createElement('button');
  innerSpan = document.createElement('span');

  /**
   * @param {Object} props the properties object for the submit button
   * @param {string} [props.text] (Optional) Defaults to *Submit*. Specifies the text displayed inside the submit button.
   * @param {string} [props.level] (Optional) Defaults to *primary*. Specifies the button level - valid options are primary and secondary.
   */
  constructor(props) {
    this.props = props;
    this.initializeButton();
    this.initializeInnerSpan();
    this.addEventListeners();
  }

  initializeButton = () => {
    this.html.ref = this;
    this.html.classList.add(FKP_BUTTON_CLASS);

    const { id, name, type, classes, level } = this.props;

    this.html.classList.add(`button--${level === 'primary' ? 'primary' : 'secondary'}`);

    if (id) {
      this.html.id = id;
    }
    if (name) {
      this.html.name = name;
    }
    if (type) {
      this.html.type = type;
    }
    if (classes) {
      this.html.classList.append(...classes);
    }
  };

  initializeInnerSpan = () => {
    const { text = 'Submit' } = this.props;
    this.innerSpan.innerText = text;
    this.html.append(this.innerSpan);
  };

  addEventListeners = () => {
    this.html.addEventListener('click', this.onClick);
  };

  reportValidity = () => {
    return true;
  };

  onClick = () => {
    this.props.onClick?.();
  };
}
