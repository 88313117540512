export default class SingleText {
  html = null;

  /**
   *
   * @param {Object} props
   * @param {string} [props.text] (Optional) The text for the element.
   * @param {string} [props.level] (Optional) The tag/level of text for the element (span, h2, h3, ...) (defaults to span)
   * @param {string[]} [props.classes] (Optional) Specifies the classes for the text element.
   */
  constructor(props) {
    this.props = props;
    this.initializeText();
  }

  initializeText = () => {
    const { classes, text, level } = this.props;
    const textLevel = level ? level : 'span';
    this.html = document.createElement(textLevel);
    if (!this.html) {
      console.error('SingleText creation failed, tag unknown: ' + textLevel);
      return;
    }

    if (classes) {
      this.html.classList.add(...classes);
    }

    if (text) {
      this.html.innerText = text;
    }
  };

  setText = (text) => {
    this.html.innerText = text;
  };

  dispatchEvent = (eventName, detail) => {
    if (!this.html) {
      throw new Error('HTML element not initialized');
    }
    this.html.dispatchEvent(new CustomEvent(eventName, { detail }));
  };

  addEventListener = (eventName, handler) => {
    if (!this.html) {
      throw new Error('HTML element not initialized');
    }
    this.html.addEventListener(eventName, handler);
  };
}
