import { KratomDiscountContainer, KratomSampleContainer, OptionOrText } from '../components/form/field/fields.js';
import { Stage } from './index.js';
import { Stage1Form } from '../forms';
import PopupContainer from '../components/form/PopupContainer.js';
import Container from '../components/Container.js';
import FormContainer from '../components/FormContainer';
import { FKP_STAGE_CLASS } from './stageConstants';
import SmsDisclaimerParagraph from '../components/SmsDisclaimerParagraph.js';

export default class Stage1 extends Stage {
  static STAGE_CLASS_NAME = 'stage-1';
  static STAGE_NAME = 'STAGE_1';

  constructor(popup) {
    super({
      popup: popup,
      stageName: Stage1.STAGE_NAME,
      formConstructor: Stage1Form,
    });
  }

  buildStageContainer(form) {
    const children = this.buildChildren(form);
    const stageContainer = new PopupContainer(this.popup, {
      classes: [Stage1.STAGE_CLASS_NAME],
      children: children,
      onShow: form?.props?.onShow,
      onLoad: this.handleLoad.bind(this, form),
      form: form,
      hideOnLoad: this.hideOnLoad,
    });
    return stageContainer;
  }

  buildChildren(form) {
    const props = {
      assetUrl: this.popup.popupAssetUrl,
      loading: 'eager',
    };
    const kratomDiscountContainer = KratomDiscountContainer(props);
    const optionOrText = OptionOrText();
    const kratomSampleContainer = KratomSampleContainer(props);
    const innerStageContainer = new Container({
      classes: [FKP_STAGE_CLASS],
      children: [kratomDiscountContainer, optionOrText, kratomSampleContainer],
    });
    const formContainer = new FormContainer(form);
    const smsDisclaimer = new SmsDisclaimerParagraph();
    return [innerStageContainer, formContainer, smsDisclaimer];
  }

  handleLoad(form, popupContainer) {
    form?.props?.onLoad?.(popupContainer);
    this.popup.toggleFullSize(false);
  }
}
