import { FORM_FIELD_CLASSES, FORM_FIELD_ELEMENT_TYPES } from '../formConstants';

export default class FormFieldInput {
  html = null;

  constructor(props) {
    this.create(props);
  }

  create = (props) => {
    const { type } = props;

    // Determine whether it's a select with options or standard input
    const inputType =
      type === FORM_FIELD_ELEMENT_TYPES.SELECT ? FORM_FIELD_ELEMENT_TYPES.SELECT : FORM_FIELD_ELEMENT_TYPES.INPUT;

    this.html = document.createElement(inputType);
    this.html.ref = props.ref;
    this.html.classList.add(FORM_FIELD_CLASSES.INPUT);
    this.html.classList.add('input');

    this.setAttributes(props);

    if (type === FORM_FIELD_ELEMENT_TYPES.SELECT) {
      this.setOptions(props);
    }
  };

  setAttributes = (props) => {
    const attributes = [
      'autocomplete',
      'autocorrect',
      'autofocus',
      'id',
      'maxLength',
      'minLength',
      'name',
      'placeholder',
      'size',
      'type',
      'value',
    ];
    for (const attribute of attributes) {
      const value = props[attribute];

      // No need to add a type for select inputs
      const typeIsSelect = attribute === 'type' && props.type === FORM_FIELD_ELEMENT_TYPES.SELECT;
      if (typeIsSelect) {
        continue;
      }

      if (value != null && value !== '') {
        this.html[attribute] = value;
      }
    }
  };

  setOptions = (props) => {
    const { selectOptions, selectOptionsIncludePlaceholder, placeholder } = props;

    const createOption = (option) => {
      const optionHtml = document.createElement('option');
      Object.entries(option).forEach(([key, value]) => (optionHtml[key] = value));
      return optionHtml;
    };

    if (selectOptionsIncludePlaceholder) {
      selectOptions.unshift({
        innerText: placeholder,
        disabled: true,
        selected: true,
        value: '',
      });
    }

    this.html.append(...selectOptions.map(createOption));
  };
}
