export default class PopupState {
  constructor(state = {}) {
    const defaultState = {
      currentStage: 1,
      sessionUuid: null,
      address: {},
      predictions: [],
      claimType: null,
      discountCode: null,
      email: null,
      order: {},
      smsNumber: null,
      shopifyCustomerId: null,
    };

    this.state = { ...defaultState, ...state };
  }

  getCurrentStageNumber() {
    return this.state.currentStage;
  }

  setCurrentStage(stageIndex) {
    this.state.currentStage = stageIndex;
  }

  setSessionUuid(uuid) {
    this.state.sessionUuid = uuid;
  }

  getSessionUuid() {
    return this.state.sessionUuid;
  }

  setClaimType(claimType) {
    this.state.claimType = claimType;
  }

  getClaimType() {
    return this.state.claimType;
  }

  setEmail(email) {
    this.state.email = email;
  }

  getEmail() {
    return this.state.email;
  }

  setDiscountCode(discountCode) {
    this.state.discountCode = discountCode;
  }

  getDiscountCode() {
    return this.state.discountCode;
  }

  setAddress(address) {
    this.state.address = { ...this.state.address, ...address };
  }

  getAddress() {
    return this.state.address;
  }

  setOrderDetails(order) {
    this.state.order = { ...this.state.order, ...order };
  }

  getOrder() {
    return this.state.order;
  }

  getSmsNumber() {
    return this.state.smsNumber;
  }

  setSmsNumber(smsNumber) {
    this.state.smsNumber = smsNumber;
  }

  getShopifyCustomerId() {
    return this.state.shopifyCustomerId;
  }

  setShopifyCustomerId(shopifyCustomerId) {
    this.state.shopifyCustomerId = shopifyCustomerId;
  }
}
