import FKPPopup from './src/FKPPopup.js';
import PopupConfig from './src/PopupConfig.js';
import PopupState from './src/PopupState.js';
import { SentryUtils } from './src/Sentry';

setTimeout(async () => {
  try {
    SentryUtils.init();
    const popupState = new PopupState();
    const config = new PopupConfig();
    const fkpPopup = new FKPPopup(popupState, config);
    await fkpPopup.initialize();
  } catch (error) {
    console.error(`An error occurred while initializing the FKPPopup: ${error}`);
  }
});
