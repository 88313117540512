import {
  KratomDiscountImage,
  KratomSampleImage,
  SmsMessageText,
  StageHeader,
} from '../components/form/field/fields.js';
import PopupContainer from '../components/form/PopupContainer.js';
import Container from '../components/Container.js';
import { Stage2Form } from '../forms';
import Stage from './Stage.js';
import { BASE_SMS_MESSAGE } from '../constants.js';
import { getLastCharacters } from '../utils.js';
import FormContainer from '../components/FormContainer';
import { FKP_STAGE_CLASS, FKP_STAGE_CONTENT_CLASS } from './stageConstants';

export default class Stage2 extends Stage {
  static STAGE_CLASS_NAME = 'stage-2';
  static STAGE_NAME = 'STAGE_2';

  constructor(popup) {
    super({
      popup: popup,
      stageName: Stage2.STAGE_NAME,
      formConstructor: Stage2Form,
    });
    this.smsMessageText = null;
    this.updateSmsText = this.updateSmsText.bind(this);
  }

  buildStageContainer(form) {
    const children = this.buildChildren(form);
    const stageContainer = new PopupContainer(this.popup, {
      classes: [Stage2.STAGE_CLASS_NAME],
      children: children,
      onShow: form?.props?.onShow,
      onLoad: this.handleLoad.bind(this, form),
      hideOnLoad: this.hideOnLoad,
    });
    return stageContainer;
  }

  buildChildren(form) {
    const stageContent = this.buildStageContent(form);
    const props = {
      assetUrl: this.popup.popupAssetUrl,
    };
    const kratomDiscountImage = KratomDiscountImage(props);
    const kratomSampleImage = KratomSampleImage(props);
    const innerStageContainer = new Container({
      classes: [FKP_STAGE_CLASS],
      children: [kratomDiscountImage, stageContent, kratomSampleImage],
    });
    return [innerStageContainer];
  }

  buildStageContent(form) {
    const { title } = this.getStageTitleAndDescription();
    this.smsMessageText = this.buildSmsMessageText();
    const formContainer = new FormContainer(form);

    const stageInformation = StageHeader(title);

    const stageContent = new Container({
      classes: [FKP_STAGE_CONTENT_CLASS],
      children: [stageInformation, this.smsMessageText, formContainer],
    });
    return stageContent;
  }

  buildSmsMessageText() {
    const smsNumber = this.popup.getSmsNumber();
    const smsNumberText = SmsMessageText(smsNumber);
    smsNumberText.addEventListener('update', () => {
      this.updateSmsText(smsNumberText);
    });
    return smsNumberText;
  }

  updateSmsText(smsNumberText) {
    const message = this.buildSmsMessage();
    smsNumberText?.setText?.(message);
  }

  buildSmsMessage() {
    const NUMBER_OF_DIGITS = 4;
    const smsNumber = this.popup.getSmsNumber();
    const lastDigits = getLastCharacters(smsNumber, NUMBER_OF_DIGITS);
    let message = BASE_SMS_MESSAGE;
    if (lastDigits) {
      message += ` ending in ******${lastDigits}`;
    }
    return message;
  }

  handleLoad(form, popupContainer) {
    form?.props?.onLoad?.(popupContainer);
    this.smsMessageText.dispatchEvent('update');
    this.popup.toggleFullSize(false);
  }
}
