import IconDownCaret from '../../icon/IconDownCaret';
import FormFieldInput from './FormFieldInput';
import FormFieldLabel from './FormFieldLabel';
import FormFieldWrapper from './FormFieldWrapper';
import Notice from '../../Notice';
import { FORM_FIELD_CLASSES, FORM_FIELD_ELEMENT_TYPES } from '../formConstants';

const FIELD_CARET_CLASS = 'field__caret';

export default class FormField {
  html = null;

  defaultProps = {
    ref: this,
    ariaAutocomplete: '',
    ariaExpanded: false,
    ariaHasPopup: false,
    ariaRequired: false,
    autocomplete: 'off',
    autocorrect: 'off',
    autofocus: false,
    classes: [],
    errorId: null,
    errorText: null,
    id: null,
    label: '',
    maxLength: null,
    minLength: null,
    name: null,
    pattern: null,
    placeholder: '',
    required: false,
    role: null,
    selectOptions: [],
    selectOptionsIncludePlacholder: false,
    size: null,
    type: 'text',
    value: '',
    onInput: () => {},
    onClick: () => {},
    onFocus: () => {},
    onKeyDown: () => {},
    onKeyUp: () => {},
    afterCreate: () => {},
  };

  props = {};

  constructor(props) {
    this.props = { ...this.defaultProps, ...props };
    this.create();
  }

  create = () => {
    this.html = document.createElement(FORM_FIELD_ELEMENT_TYPES.FIELD);
    this.wrapper = new FormFieldWrapper(this.props);
    this.input = new FormFieldInput(this.props);
    this.label = new FormFieldLabel(this.props);
    const { errorText } = this.props;
    if (errorText) {
      this.error = new Notice(this.props);
      this.error.setText('');
    }

    this.addClasses();
    if (this.props.required) {
      this.input.html.required = true;
    }

    this.html.appendChild(this.wrapper.html);
    this.wrapper.html.append(this.input.html, this.label.html);

    if (this.props.type === 'select') {
      const fieldCaret = document.createElement('div');
      fieldCaret.classList.add(FIELD_CARET_CLASS);
      fieldCaret.append(IconDownCaret());
      this.wrapper.html.append(fieldCaret);
    }

    if (this.error) {
      this.html.append(this.error.html);
    }

    this.addEventListeners();
    this.props.afterCreate(this);
  };

  addClasses = () => {
    const fieldClasses = [FORM_FIELD_CLASSES.FIELD, ...this.props.classes];
    this.html.classList.add(...fieldClasses);
  };

  reportValidity = () => {
    const { required, pattern } = this.props;
    const { value } = this.input.html;
    if (required && !value) {
      return this.showError();
    }
    if (pattern && !pattern.test(value)) {
      return this.showError();
    }
    return this.hideError();
  };

  showError = () => {
    if (!this.error) {
      return;
    }
    const { errorId, errorText } = this.props;
    if (errorId) {
      this.input.html.setAttribute('aria-describedby', errorId);
    }
    this.input.html.setAttribute('aria-invalid', 'true');

    this.html.classList.add(FORM_FIELD_CLASSES.ERROR);
    this.error.showError(errorText);
    return false;
  };

  hideError = () => {
    if (!this.error) {
      return;
    }
    this.input.html.removeAttribute('aria-describedby');
    this.input.html.removeAttribute('aria-invalid');
    this.html.classList.remove(FORM_FIELD_CLASSES.ERROR);
    this.error.clear();
    return true;
  };

  addEventListeners = () => {
    this.input.html.addEventListener('input', this.onInput);
    this.input.html.addEventListener('invalid', this.onInvalid);
    this.input.html.addEventListener('focus', this.onFocus);
    this.input.html.addEventListener('blur', this.onBlur);
  };

  onInput = (event) => {
    this.props.onInput(event);
  };

  onInvalid = (event) => {
    event.preventDefault();
  };

  setValue = (value) => {
    if (this.input?.html) {
      this.input.html.value = value;
    }
  };

  getValue = () => {
    return this?.input?.html?.value;
  };
}
