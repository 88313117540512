import Container from './Container';

const FKP_STAGE_FORM_CONTAINER_CLASS = 'fkp__stage-form-container';

export default class FormContainer extends Container {
  constructor(form) {
    const props = {
      classes: [FKP_STAGE_FORM_CONTAINER_CLASS],
      children: [form],
    };
    super(props);
  }
}
