import Notice from './components/Notice';

const FKP_NOTIFICATION_CLASS = 'fkp__notification';
const FKP_NOTIFICATION_SHOW_CLASS = 'show';
const FKP_NOTIFICATION_ERROR_CLASS = 'error';
const FKP_NOTIFICATION_SUCCESS_CLASS = 'success';

export default class PopupNotification {
  html = null;
  notice = null;

  constructor() {
    this.constructNotification();
  }

  constructNotification() {
    this.html = document.createElement('div');
    this.html.classList.add(FKP_NOTIFICATION_CLASS);

    const notice = new Notice();
    this.html.appendChild(notice.html);
    this.notice = notice;
  }

  showError(message) {
    this.notice.showError(message);
    this.html.classList.add(FKP_NOTIFICATION_SHOW_CLASS, FKP_NOTIFICATION_ERROR_CLASS);
  }

  showSuccess(message) {
    this.notice.showSuccess(message);
    this.html.classList.add(FKP_NOTIFICATION_SHOW_CLASS, FKP_NOTIFICATION_SUCCESS_CLASS);
  }

  clear() {
    const NOTIFICATION_CLASSES = [
      FKP_NOTIFICATION_SHOW_CLASS,
      FKP_NOTIFICATION_ERROR_CLASS,
      FKP_NOTIFICATION_SUCCESS_CLASS,
    ];
    this.html.classList.remove(...NOTIFICATION_CLASSES);
    this.notice.clear();
  }
}
