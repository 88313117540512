import IconGiftbox from './components/icon/IconGiftbox.js';
import IconX from './components/icon/IconX.js';
import {
  clamp,
  convertObjToQueryString,
  createStrippedUrlString,
  getLoggedInCustomer,
  hideZendeskButton,
  isScreenSmall,
  processEmail,
  processPhoneNumber,
  showZendeskButton,
} from './utils.js';
import {
  DEFAULT_ERROR_CONTEXT,
  DEFAULT_ERROR_STATUS,
  DEFAULT_SUCCESS_MESSAGE,
  FKP_BACK_BUTTON_HIDDEN_CLASS,
  FKP_BUTTON_CLASS,
  FKP_BUTTON_PLAIN_CLASS,
  FKP_CLAIM_TYPE,
  FKP_DISPLAY_BUTTON_HIDDEN_CLASS,
  FKP_ICON_BUTTON_CLASS,
  FKP_PAGE_FORM_CONTAINER_CLASS,
  FKP_POPUP_BACK_BUTTON_CLASS,
  FKP_POPUP_BODY_CLASS,
  FKP_POPUP_BUTTON_CLASS,
  FKP_POPUP_CLASS,
  FKP_POPUP_CLOSE_BUTTON_CLASS,
  FKP_POPUP_FULL_SIZE_CLASS,
  FKP_POPUP_HEADER_CLASS,
  FKP_POPUP_HIDDEN_CLASS,
  FKS_ENDPOINT_GET_CONFIG,
  STAGES,
} from './constants.js';
import IconArrowLeft from './components/icon/IconArrowLeft.js';
import LocalStorageService from './LocalStorageService';
import StageBuilder from './stage/StageBuilder';
import stages from './stage/stageList';
import PopupNotification from './PopupNotification';

/**
 * @property {Stage[]} stages
 * @property {PopupNotification} notification
 * @property {PopupState} popupState
 * @property {PopupConfig} config
 * @requires [js-cookie](https://cdn.jsdelivr.net/npm/js-cookie@3.0.1/dist/js.cookie.min.js)
 */
export default class FKPPopup {
  static CLAIM_TYPE_TO_STAGE = {
    [FKP_CLAIM_TYPE.SAMPLES]: STAGES.STAGE_4A,
    [FKP_CLAIM_TYPE.DISCOUNT]: STAGES.STAGE_4B,
  };
  static LAST_DISPLAYED_COOKIE_NAME = 'campaign_popup_last_displayed';
  static LOCAL_STORAGE_KEYS = {
    STATE: 'fkpPopupState',
    OFFER_CLAIMED: 'fkpOfferClaimed',
    CHECKOUT_DATA: 'fkpCheckoutData',
  };

  static START_STAGE = STAGES.STAGE_1;
  static END_STAGES = [STAGES.STAGE_6A, STAGES.STAGE_6B];

  shopifyStoreUrl = null;
  popupAssetUrl = null;
  apiBaseUrl = null;
  config = {};
  popupState = {};
  stages = [];
  dom = {};
  notification = null;

  /**
   * @param {PopupState} state
   * @param {PopupConfig} config
   */
  constructor(state, config) {
    if (!state || !config) {
      throw new Error('FKPPopup requires state and config to be provided');
    }

    this.popupState = state;
    this.config = config;

    const savedState = this.getSavedState();
    if (savedState) {
      this.popupState.state = { ...savedState };
    }
  }

  /**
   * @return HTMLElement
   */
  html = () => {
    return this.dom.popupContainer;
  };

  initialize = async () => {
    if (this.offerClaimed()) {
      console.log(`[FKSPopup#initialize] Offer Claimed`);
      return;
    }

    try {
      this.initializeScriptData();
      await this.updateConfig();
      if (this.config.offerClaimed()) {
        console.log(`[FKSPopup#initialize] Updating offer claimed`);
        this.setOfferClaimed();
        return;
      }

      await this.buildPopup();
      this.appendToDom();
      this.automaticallyDisplay();
    } catch (error) {
      console.info(error);
    }
  };

  initializeScriptData = () => {
    const script = this.getClientScript();
    this.setUrlsFromScript(script);
  };

  getClientScript = () => {
    const FKP_SCRIPT_ID = 'fkp__script';
    const script = document.querySelector(`#${FKP_SCRIPT_ID}`);
    if (!script) {
      throw new Error('Unable to get client script.');
    }
    return script;
  };

  setUrlsFromScript(script) {
    const { apiBaseUrl, popupAssetUrl, shopifyStoreUrl } = script.dataset;

    try {
      this.apiBaseUrl = createStrippedUrlString(apiBaseUrl);
      this.shopifyStoreUrl = createStrippedUrlString(shopifyStoreUrl);
      this.popupAssetUrl = createStrippedUrlString(popupAssetUrl);
    } catch (error) {
      throw new Error(`Invalid url:\n ${error.message}`);
    }
  }

  updateConfig = async () => {
    const configJson = await this.getConfig();
    this.config.setConfigFromJson(configJson);
  };

  offerClaimed = () => {
    const offerClaimed = this.getOfferClaimed();
    return Boolean(offerClaimed);
  };

  buildPopup = async () => {
    console.log(`[FKPPopup] Building popup`);
    this.checkEnabled();
    this.initializeStages();
    this.initializeDom();

    this.constructPopupHtml();
    const stageNumber = this.getCurrentStageNumber();
    this.handleBackButtonForStage(stageNumber);
    this.loadNotificationForStage(stageNumber);
    await this.loadStage(stageNumber);
  };

  initializeDom = () => {
    this.dom = {
      popupContainer: document.createElement('div'),
      popupCloseButton: document.createElement('button'),
      popupBackButton: document.createElement('button'),
      popupBody: document.createElement('div'),
    };
  };

  initializeStages = () => {
    try {
      this.stages = StageBuilder.createStagesForPopup(this, stages);
    } catch (error) {
      console.error(error);
    }
  };

  getLastPopupDisplayedTime = () => {
    const lastDisplayedCookie = this.getCookieByName(FKPPopup.LAST_DISPLAYED_COOKIE_NAME);
    return Number(lastDisplayedCookie) || 0;
  };

  getCookieByName = (cookieName) => {
    if (!window.Cookies || !cookieName) {
      return null;
    }
    return window.Cookies.get(cookieName);
  };

  canAutomaticallyDisplay = () => {
    if (this.isFreeKratomPage()) {
      return true;
    }
    if (!this.isEnabled()) {
      return false;
    }

    const nextDisplayTime = this.getLastPopupDisplayedTime() + this.getDisplayDelayMs();
    return Date.now() >= nextDisplayTime;
  };

  isEnabled = () => {
    const apiConfig = this.config?.getApiConfiguration?.();
    return apiConfig?.is_popup_enabled || false;
  };

  checkEnabled = () => {
    if (!this.isEnabled()) {
      throw new Error('Free Kratom popup inactive.');
    }
  };

  getLandingPageFormContainer = () => {
    const FKS_PAGE_FORM_CONTAINER_SELECTOR = `.${FKP_PAGE_FORM_CONTAINER_CLASS}`;
    return document.querySelector(FKS_PAGE_FORM_CONTAINER_SELECTOR);
  };

  isFreeKratomPage = () => {
    const landingPageFormContainer = this.getLandingPageFormContainer();
    return Boolean(landingPageFormContainer);
  };

  automaticallyDisplay = () => {
    if (!this.canAutomaticallyDisplay()) {
      console.log(`[FKPPopup] Cannot automatically display`);
      return;
    }

    if (this.isFreeKratomPage()) {
      console.log(`[FKPPopup] Displaying for Free Kratom Page`);
      this.displayForFreeKratomPage();
      return;
    }

    setTimeout(() => this.display(), this.getWaitBeforeDisplayMs());
  };

  isPopupClosed = () => {
    const popup = this.dom?.popupContainer;
    return Boolean(popup.classList?.contains?.(FKP_POPUP_HIDDEN_CLASS));
  };

  display = () => {
    if (!this.isPopupClosed()) {
      console.log(`[FKPPopup] display - popup is already open`);
      return;
    }

    if (isScreenSmall()) {
      console.log(`[FKPPopup] screen is small, hiding ZenDesk button`);
      hideZendeskButton();
    }
    this.showCurrentStage();
    this.dom.popupContainer.classList.remove(FKP_POPUP_HIDDEN_CLASS);
    this.hidePopupDisplayButton();
  };

  displayForFreeKratomPage = () => {
    this.showCurrentStage();
  };

  hidePopupDisplayButton = () => {
    console.log('FKPPopup - Hiding popup display button');
    const popupDisplayButton = document.querySelector(`.${FKP_POPUP_BUTTON_CLASS}`);
    if (popupDisplayButton) {
      popupDisplayButton.classList.add(FKP_DISPLAY_BUTTON_HIDDEN_CLASS);
    }
  };

  close = () => {
    console.log(`[FKPPopup - Closing popup]`);
    if (this.isFreeKratomPage()) {
      console.log(`[FKPPopup - Is Free Kratom Page, short-circuiting close()`);
      return;
    }

    if (isScreenSmall()) {
      showZendeskButton();
    }
    this.setCookieLastDisplayed();
    this.dom.popupContainer.classList.add(FKP_POPUP_HIDDEN_CLASS);
    this.showPopupDisplayButton();
  };

  showPopupDisplayButton = () => {
    const popupDisplayButton = document.querySelector(`.${FKP_POPUP_BUTTON_CLASS}`);
    if (popupDisplayButton) {
      popupDisplayButton.classList.remove(FKP_DISPLAY_BUTTON_HIDDEN_CLASS);
    }
  };

  setStage = async (stageNumber) => {
    if (!stageNumber) {
      return;
    }

    const validStageNumber = this.validateStageNumber(stageNumber);
    this.handleBackButtonForStage(stageNumber);
    await this.switchStages(validStageNumber);
    this.clearNotification();
  };

  handleBackButtonForStage = (stageNumber) => {
    const invalidStagesSet = new Set([FKPPopup.START_STAGE, ...FKPPopup.END_STAGES]);
    if (invalidStagesSet.has(stageNumber)) {
      this.hideBackButton();
    } else {
      this.showBackButton();
    }
  };

  validateStageNumber = (stageNumber) => {
    const stagesLength = this.stages?.length ?? 0;
    return clamp(stageNumber, 1, stagesLength);
  };

  switchStages = async (newStageNumber) => {
    const currentStage = this.getStageByNumber(this.getCurrentStageNumber());
    const newStage = this.getStageByNumber(newStageNumber);

    await this.loadStage(newStageNumber);
    currentStage.hide();
    newStage.show();
  };

  showCurrentStage = () => {
    const currentStageNumber = this.getCurrentStageNumber();
    this.showStage(currentStageNumber);
  };

  /**
   * @return {Stage}
   * @param {number} stageNumber
   */
  getStageByNumber = (stageNumber) => {
    if (!stageNumber) {
      return null;
    }

    const targetIndex = stageNumber - 1;
    return this.stages?.[targetIndex];
  };

  loadStage = async (stageNumber) => {
    console.log(`[FKPPopup] loadStage(${stageNumber})`);
    const targetStage = this.getStageByNumber(stageNumber);
    if (!targetStage) {
      console.error(`Cannot load stage :${stageNumber}`);
      return;
    }

    await targetStage.load();
    this.popupState.setCurrentStage(stageNumber);
    this.saveCurrentState();
    this.loadNotificationForStage(stageNumber);
  };

  loadNotificationForStage = (stageNumber) => {
    const stage = this.getStageByNumber(stageNumber);
    if (!stage) {
      console.error('stage undefined, could not load notification in form');
      return;
    }
    stage.insertNotificationInForm(this.notification.html);
  };

  showStage = (stageNumber) => {
    const targetStage = this.getStageByNumber(stageNumber);
    if (!targetStage) {
      console.error(`stageNumber: ${stageNumber} not found`);
      return;
    }
    targetStage.show();
  };

  notifyError = (payload = {}) => {
    const { status = DEFAULT_ERROR_STATUS, context = DEFAULT_ERROR_CONTEXT } = payload;
    const errorMessage = `${status}: ${context}`;
    this.notification.showError(errorMessage);
  };

  notifySuccess = (payload = {}) => {
    const { context = DEFAULT_SUCCESS_MESSAGE } = payload;
    this.notification.showSuccess(context);
  };

  clearNotification = () => {
    this.notification.clear();
  };

  setCookieLastDisplayed = () => {
    const cookieName = FKPPopup.LAST_DISPLAYED_COOKIE_NAME;
    const daysToExpiry = 365;
    const currentTime = Date.now();
    this.setCookieByName(cookieName, currentTime, { expires: daysToExpiry });
  };

  setCookieByName = (cookieName, value, options = {}) => {
    if (!cookieName || !value) {
      console.error('cookieName or value undefined.');
    }
    window.Cookies.set(cookieName, value, options);
  };

  getConfig = async () => {
    const customerQueryParams = this.buildCustomerQueryParams();
    const responseJson = await this.fetchConfig(customerQueryParams);
    this.validatePayloadJSON(responseJson);
    return responseJson;
  };

  buildCustomerQueryParams = () => {
    const { email = null, id = null, phone = null } = getLoggedInCustomer() || {};
    this.setShopifyCustomerId(id);
    const { checkoutPhone = null, checkoutEmail = null } = this.getSavedCheckoutData() || {};

    const customerEmail = processEmail(email || checkoutEmail);
    const customerPhone = processPhoneNumber(phone || checkoutPhone);

    return {
      email: customerEmail,
      phone: customerPhone,
      shopify_customer_id: id,
    };
  };

  fetchConfig = async (queryParamsObj = {}) => {
    let url = this.apiBaseUrl + FKS_ENDPOINT_GET_CONFIG;
    const queryString = convertObjToQueryString(queryParamsObj);
    if (queryString) {
      url += `?${queryString}`;
    }

    const response = await fetch(url, {
      method: 'get',
      mode: 'cors',
      cache: 'no-cache',
    });
    return await response.json();
  };

  validatePayloadJSON = (json) => {
    // Naive check top level props to see if it got valid json
    const topLevelProps = ['popup_configuration', 'api_configuration', 'offer_claimed'];
    topLevelProps.forEach((prop) => {
      if (!json.hasOwnProperty(prop)) {
        throw new Error(`Invalid popup payload JSON. Missing key: ${prop}`);
      }
    });
  };

  constructPopupHtml = () => {
    this.notification = new PopupNotification();
    this.setupPopupContainer();
    this.addPopupContents();
  };

  setupPopupContainer = () => {
    const { popupContainer } = this.dom;
    popupContainer.classList.add(FKP_POPUP_CLASS, FKP_POPUP_HIDDEN_CLASS);
    popupContainer.setAttribute('role', 'dialog');
  };

  addPopupContents = () => {
    const { popupContainer, popupBody } = this.dom;
    const popupHeader = this.constructPopupHeader();

    popupContainer.append(popupHeader);

    popupBody.classList.add(FKP_POPUP_BODY_CLASS);
    popupContainer.append(popupBody);

    this.addStagesToPopupBody();
  };

  constructPopupHeader = () => {
    const popupHeader = document.createElement('div');
    popupHeader.classList.add(FKP_POPUP_HEADER_CLASS);
    this.setupBackButton();
    this.setupCloseButton();
    popupHeader.append(this.dom.popupBackButton);
    if (!this.isFreeKratomPage()) {
      popupHeader.append(this.dom.popupCloseButton);
    }
    return popupHeader;
  };

  addStagesToPopupBody = () => {
    const stagesElements = this.stages.map((stage) => stage.getStageHTML());
    this.dom.popupBody.append(...stagesElements);
  };

  setupBackButton = () => {
    const backButton = this.dom.popupBackButton;
    backButton.classList.add(FKP_BUTTON_CLASS, FKP_BUTTON_PLAIN_CLASS);
    backButton.classList.add(FKP_POPUP_BACK_BUTTON_CLASS);

    backButton.addEventListener('click', async () => {
      await this.setStage(this.getPreviousStage());
    });

    const BACK_BUTTON_TEXT = 'Back';
    const svgIconBack = IconArrowLeft();
    const backButtonText = document.createTextNode(BACK_BUTTON_TEXT);
    backButton.append(svgIconBack, backButtonText);
  };

  setupCloseButton = () => {
    const closeButton = this.dom.popupCloseButton;
    closeButton.classList.add(FKP_ICON_BUTTON_CLASS, FKP_POPUP_CLOSE_BUTTON_CLASS);
    closeButton.setAttribute('aria-label', 'Close');
    closeButton.addEventListener('click', () => this.close());

    const svgIconX = IconX();
    closeButton.append(svgIconX);
  };

  getPreviousStage = () => {
    const currentStageNumber = this.getCurrentStageNumber();
    if (currentStageNumber === STAGES.STAGE_1) {
      return STAGES.STAGE_1;
    }
    if (currentStageNumber === STAGES.STAGE_4B) {
      return STAGES.STAGE_3;
    }
    return currentStageNumber - 1;
  };

  hideBackButton = () => {
    const backButton = this.dom.popupBackButton;
    if (backButton) {
      backButton.classList.add(FKP_BACK_BUTTON_HIDDEN_CLASS);
    }
  };

  showBackButton = () => {
    const backButton = this.dom.popupBackButton;
    if (backButton) {
      backButton.classList.remove(FKP_BACK_BUTTON_HIDDEN_CLASS);
    }
  };

  addPopupOpenButtonToDom = () => {
    const popupDisplayButton = this.createPopupDisplayButton();
    if (!popupDisplayButton) {
      console.error('popupDisplayButton undefined');
      return;
    }
    document.body.append(popupDisplayButton);
  };

  createPopupDisplayButton = () => {
    const popupDisplayButton = document.createElement('div');
    popupDisplayButton.addEventListener('click', () => this.display());
    popupDisplayButton.classList.add(FKP_POPUP_BUTTON_CLASS);

    const popupButtonText = 'Free Kratom';
    const popupDisplayButtonText = document.createElement('span');
    popupDisplayButtonText.innerText = popupButtonText;

    const svgGiftBox = IconGiftbox();
    popupDisplayButton.append(svgGiftBox, popupDisplayButtonText);
    return popupDisplayButton;
  };

  appendToDom = () => {
    if (this.isFreeKratomPage()) {
      this.appendBodyHtmlToFormContainer();
      this.dom.popupContainer.classList.remove(FKP_POPUP_HIDDEN_CLASS);
    } else {
      this.addPopupOpenButtonToDom();
      this.appendPopupHtmlToBody();
    }
  };

  appendPopupHtmlToBody = () => {
    const popupHtml = this.html();
    document.body.appendChild(popupHtml);
  };

  appendBodyHtmlToFormContainer = () => {
    const fksPageFormContainer = this.getLandingPageFormContainer();
    if (fksPageFormContainer) {
      fksPageFormContainer.after(this.dom.popupContainer);
    }
  };

  toggleFullSize(fullSize = true) {
    const popupContainer = this.dom.popupContainer;
    if (!popupContainer) {
      console.error('popupContainer is undefined');
      return;
    }
    popupContainer.classList.toggle(FKP_POPUP_FULL_SIZE_CLASS, fullSize);
  }

  setSessionUuid = (uuid) => {
    if (!uuid) {
      this.notifyError({
        status: 'Error 2107',
        context: 'Invalid session. Please try again later.',
      });
    }
    this.popupState.setSessionUuid(uuid);
    this.saveCurrentState();
  };

  getSessionUuid = () => {
    return this.popupState.getSessionUuid();
  };

  setClaimType = (claimType) => {
    if (!claimType) {
      return;
    }
    this.popupState.setClaimType(claimType);
    this.saveCurrentState();
  };

  getClaimType = () => {
    return this.popupState.getClaimType();
  };

  submitStageForm = (stageNumber) => {
    const stage = this.getStageByNumber(stageNumber);
    stage.submitForm();
  };

  setStageFromClaimType = async () => {
    const claimType = this.getClaimType();
    const stage = FKPPopup.CLAIM_TYPE_TO_STAGE[claimType];
    if (!stage) {
      console.error('Unknown claim type, could not set stage');
      return;
    }
    await this.setStage(stage);
  };

  setAddress = (address) => {
    if (!address) {
      return;
    }
    this.popupState.setAddress(address);
    this.saveCurrentState();
  };

  getAddress = () => {
    return this.popupState.getAddress();
  };

  setOrderDetails = (orderDetails = {}) => {
    this.popupState.setOrderDetails(orderDetails);
    this.saveCurrentState();
  };

  setEmail = (email) => {
    this.popupState.setEmail(email);
    this.saveCurrentState();
  };

  getEmail = () => {
    return this.popupState.getEmail();
  };

  setDiscountCode = (discountCode = '') => {
    this.popupState.setDiscountCode(discountCode);
    this.saveCurrentState();
  };

  getDiscountCode = () => {
    return this.popupState.getDiscountCode();
  };

  getOrder = () => {
    return this.popupState.getOrder();
  };

  getSmsNumber = () => {
    return this.popupState.getSmsNumber();
  };

  setSmsNumber = (smsNumber) => {
    this.popupState.setSmsNumber(smsNumber);
    this.saveCurrentState();
  };

  getCurrentStageNumber = () => {
    const currentStageNumber = this.popupState.getCurrentStageNumber();
    const validatedStageNumber = this.validateStageNumber(currentStageNumber);
    return validatedStageNumber;
  };

  getStageConfiguration = (stageNumber) => {
    const popupConfig = this.config.getPopupConfiguration();
    const targetIndex = stageNumber - 1;
    return popupConfig?.stages?.[targetIndex];
  };

  getDisplayDelayMs = () => {
    const popupConfig = this.config.getPopupConfiguration();
    return popupConfig?.display_again_in_ms || 0;
  };

  getWaitBeforeDisplayMs = () => {
    const popupConfig = this.config.getPopupConfiguration();
    return popupConfig?.wait_before_display_ms || 0;
  };

  setShopifyCustomerId = (shopifyCustomerId) => {
    this.popupState.setShopifyCustomerId(shopifyCustomerId);
    this.saveCurrentState();
  };

  getShopifyCustomerId = () => {
    return this.popupState.getShopifyCustomerId();
  };

  getSavedState = () => {
    return LocalStorageService.load(FKPPopup.LOCAL_STORAGE_KEYS.STATE);
  };

  saveCurrentState = () => {
    const currentState = this.popupState.state;
    LocalStorageService.save(FKPPopup.LOCAL_STORAGE_KEYS.STATE, currentState);
  };

  setOfferClaimed = () => {
    LocalStorageService.save(FKPPopup.LOCAL_STORAGE_KEYS.OFFER_CLAIMED, true);
  };

  getOfferClaimed = () => {
    return LocalStorageService.load(FKPPopup.LOCAL_STORAGE_KEYS.OFFER_CLAIMED);
  };

  getSavedCheckoutData = () => {
    return LocalStorageService.load(FKPPopup.LOCAL_STORAGE_KEYS.CHECKOUT_DATA);
  };
}
