export const PHONE_NUMBER_PATTERN = /^\(\d{3}\)\s\d{3}-\d{4}$/;

/** @reference https://stackoverflow.com/a/201378/1578513 */
export const EMAIL_ADDRESS_PATTERN =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

export const US_STATE_OPTIONS = [
  { value: 'AL', innerText: 'Alabama', disabled: true },
  { value: 'AK', innerText: 'Alaska' },
  { value: 'AS', innerText: 'American Samoa' },
  { value: 'AZ', innerText: 'Arizona' },
  { value: 'AR', innerText: 'Arkansas', disabled: true },
  { value: 'CA', innerText: 'California' },
  { value: 'CO', innerText: 'Colorado' },
  { value: 'CT', innerText: 'Connecticut' },
  { value: 'DC', innerText: 'Washington DC' },
  { value: 'DE', innerText: 'Delaware' },
  { value: 'FL', innerText: 'Florida' },
  { value: 'FM', innerText: 'Micronesia' },
  { value: 'GA', innerText: 'Georgia' },
  { value: 'GU', innerText: 'Guam' },
  { value: 'HI', innerText: 'Hawaii' },
  { value: 'IA', innerText: 'Iowa' },
  { value: 'ID', innerText: 'Idaho' },
  { value: 'IL', innerText: 'Illinois' },
  { value: 'IN', innerText: 'Indiana', disabled: true },
  { value: 'KS', innerText: 'Kansas' },
  { value: 'KY', innerText: 'Kentucky' },
  { value: 'LA', innerText: 'Louisiana' },
  { value: 'MA', innerText: 'Massachusetts' },
  { value: 'MD', innerText: 'Maryland' },
  { value: 'ME', innerText: 'Maine' },
  { value: 'MH', innerText: 'Marshall Islands' },
  { value: 'MI', innerText: 'Michigan' },
  { value: 'MN', innerText: 'Minnesota' },
  { value: 'MO', innerText: 'Missouri' },
  { value: 'MP', innerText: 'Northern Mariana Islands' },
  { value: 'MS', innerText: 'Mississippi' },
  { value: 'MT', innerText: 'Montana' },
  { value: 'NC', innerText: 'North Carolina' },
  { value: 'ND', innerText: 'North Dakota' },
  { value: 'NE', innerText: 'Nebraska' },
  { value: 'NH', innerText: 'New Hampshire' },
  { value: 'NJ', innerText: 'New Jersey' },
  { value: 'NM', innerText: 'New Mexico' },
  { value: 'NV', innerText: 'Nevada' },
  { value: 'NY', innerText: 'New York' },
  { value: 'OH', innerText: 'Ohio' },
  { value: 'OK', innerText: 'Oklahoma' },
  { value: 'OR', innerText: 'Oregon' },
  { value: 'PA', innerText: 'Pennsylvania' },
  { value: 'PR', innerText: 'Puerto Rico' },
  { value: 'PW', innerText: 'Palau' },
  { value: 'RI', innerText: 'Rhode Island', disabled: true },
  { value: 'SC', innerText: 'South Carolina' },
  { value: 'SD', innerText: 'South Dakota' },
  { value: 'TN', innerText: 'Tennessee' },
  { value: 'TX', innerText: 'Texas' },
  { value: 'UT', innerText: 'Utah' },
  { value: 'VA', innerText: 'Virginia' },
  { value: 'VI', innerText: 'U.S. Virgin Islands' },
  { value: 'VT', innerText: 'Vermont', disabled: true },
  { value: 'WA', innerText: 'Washington' },
  { value: 'WI', innerText: 'Wisconsin', disabled: true },
  { value: 'WV', innerText: 'West Virginia' },
  { value: 'WY', innerText: 'Wyoming' },
  { value: 'AA', innerText: 'Armed Forces Americas' },
  { value: 'AE', innerText: 'Armed Forces Europe' },
  { value: 'AP', innerText: 'Armed Forces Pacific' },
].map((entry) => {
  if (entry.disabled) {
    entry.innerText += ' (prohibited)';
  }
  return entry;
});

export const CA_PROVINCE_OPTIONS = [
  { value: 'AB', innerText: 'Alberta' },
  { value: 'BC', innerText: 'British Columbia' },
  { value: 'MB', innerText: 'Manitoba' },
  { value: 'NB', innerText: 'New Brunswick' },
  { value: 'NL', innerText: 'Newfoundland and Labrador' },
  { value: 'NT', innerText: 'Northwest Territories' },
  { value: 'NS', innerText: 'Nova Scotia' },
  { value: 'NU', innerText: 'Nunavut' },
  { value: 'ON', innerText: 'Ontario' },
  { value: 'PE', innerText: 'Prince Edward Island' },
  { value: 'QC', innerText: 'Quebec' },
  { value: 'SK', innerText: 'Saskatchewan' },
  { value: 'YT', innerText: 'Yukon' },
];

export const FORM_KEY_SESSION_UUID = 'session_uuid';
export const FORM_KEY_SMS_NUMBER = 'sms_number';

export const FORM_KEY_EMAIL = 'email_address';
export const FORM_KEY_ADDRESS = {
  first_name: 'address[first_name]',
  last_name: 'address[last_name]',
  address1: 'address[address1]',
  address2: 'address[address2]',
  city: 'address[city]',
  country: 'address[country]',
  province: 'address[province]',
  zip_code: 'address[zip_code]',
};

export const STAGES = {
  STAGE_1: 1,
  STAGE_2: 2,
  STAGE_3: 3,
  STAGE_4A: 4,
  STAGE_5A: 5,
  STAGE_6A: 6,
  STAGE_4B: 7,
  STAGE_5B: 8,
  STAGE_6B: 9,
};

export const FKP_EVENT = {
  SELECT_CLAIM_TYPE: 'fkp:select-claim-type',
};

export const FKP_CLAIM_TYPE = {
  DISCOUNT: 'discount',
  SAMPLES: 'samples',
};

export const DOMPARSER_PARSE_ERROR_SELECTOR = 'parsererror';
export const FKP_BUTTON_CLASS = 'button';
export const FKP_BUTTON_PLAIN_CLASS = 'button--plain';

export const INPUT_CLASS = 'input';

export const FKP_POPUP_BUTTON_CLASS = 'fkp__popup-display-button';
export const FKP_PAGE_FORM_CONTAINER_CLASS = 'free-kratom-sign-up__form-container';

export const FKP_ICON_BUTTON_CLASS = 'icon-button';
export const FKP_POPUP_BACK_BUTTON_CLASS = 'fkp__popup-back-button';
export const FKP_POPUP_BODY_CLASS = 'fkp__popup-body';
export const FKP_POPUP_CLASS = 'fkp__popup';
export const FKP_POPUP_CLOSE_BUTTON_CLASS = 'fkp__popup-close-button';
export const FKP_POPUP_FULL_SIZE_CLASS = 'full-size';
export const FKP_POPUP_HEADER_CLASS = 'fkp__popup-header';

export const FKP_FORM_FIELD_GROUP_CLASS = 'fkp__form-field-group';

export const BASE_SMS_MESSAGE = 'A text message with a six-digit verification code has been sent to your phone number';

export const DEFAULT_ERROR_STATUS = 'Error';
export const DEFAULT_ERROR_CONTEXT = 'Unknown error occurred.';
export const DEFAULT_SUCCESS_MESSAGE = 'Operation successful.';
export const FKP_POPUP_HIDDEN_CLASS = 'fkp__popup--hidden';
export const FKP_DISPLAY_BUTTON_HIDDEN_CLASS = 'fkp__popup-display-button--hidden';
export const FKP_BACK_BUTTON_HIDDEN_CLASS = 'fkp__popup-back-button--hidden';

export const FKS_ENDPOINT_GET_CONFIG = '/public/configuration';
export const FKS_ENDPOINT_ADDRESS_SUGGEST = '/public/address/suggest';
export const FKS_ENDPOINT_ADDRESS_ZIP = '/public/address/zip';
export const FKS_ENDPOINT_EMAIL_VERIFY = '/public/email/verify';
export const FKS_ENDPOINT_CLAIM_SAMPLES = '/public/claim-samples';
export const FKS_ENDPOINT_SMS_VERIFY = '/public/sms/verify';
export const FKS_ENDPOINT_SMS_SUBMIT = '/public/sms/submit';
export const FKS_ENDPOINT_CLAIM_TYPE_SELECT = '/public/claim-type/select';
export const FKS_ENDPOINT_CLAIM_DISCOUNT = '/public/claim-discount';
export const FKS_ENDPOINT_ADDRESS_VERIFY = '/public/address/verify';

export const SHOPIFY_ENDPOINT_CREATE_ACCOUNT = '/account';
