import StageForm from './StageForm.js';
import { parseHtmlFromString } from '../utils.js';
import {
  AccountCreateText,
  EmailAddressInput,
  FormTypeInput,
  SubmitButton,
  UtfInput,
} from '../components/form/field/fields.js';
import { FKP_FORM_FIELD_GROUP_CLASS, SHOPIFY_ENDPOINT_CREATE_ACCOUNT } from '../constants.js';
import PasswordContainer from '../components/form/field/PasswordContainer.js';
import { HTTP_METHOD_POST } from './stageFormConstants';

const FORM_KEY_CREATE_CUSTOMER_EMAIL = 'customer[email]';
const FKP_CREATE_ACCOUNT_EMAIL_CLASS = 'fkp__create-account-email';
const THEME_FORM_ERROR_MSG_SELECTOR = '.notice--error .notice__message';

export default class Stage6aForm extends StageForm {
  static SUBMIT_BUTTON_TEXT = 'Create Account';
  constructor(popup = {}) {
    const urlDetails = {
      baseUrl: popup.shopifyStoreUrl,
      action: SHOPIFY_ENDPOINT_CREATE_ACCOUNT,
      method: HTTP_METHOD_POST,
    };
    const formOptions = {
      noValidate: true,
    };
    const formProps = {
      popup: popup,
      urlDetails: urlDetails,
      formOptions: formOptions,
    };
    super(formProps);

    this.passwordContainer = new PasswordContainer({
      classes: [FKP_FORM_FIELD_GROUP_CLASS],
    });
    this.emailAddresssInput = EmailAddressInput(FKP_CREATE_ACCOUNT_EMAIL_CLASS, FORM_KEY_CREATE_CUSTOMER_EMAIL);
    this.children = this.buildChildren();
    this.setDefaultEmail();
  }

  buildChildren() {
    const accountCreateText = AccountCreateText();
    const formTypeInput = FormTypeInput();
    const utfInput = UtfInput();
    const submitButton = SubmitButton(Stage6aForm.SUBMIT_BUTTON_TEXT);
    return [
      formTypeInput.html,
      utfInput.html,
      accountCreateText.html,
      this.emailAddresssInput.html,
      this.passwordContainer.html,
      submitButton.html,
    ];
  }

  getErrorMsg(responseText) {
    try {
      const parsedHtml = parseHtmlFromString(responseText);
      const themeFormErrorMsg = parsedHtml?.querySelector?.(THEME_FORM_ERROR_MSG_SELECTOR);
      return themeFormErrorMsg?.textContent;
    } catch (error) {
      return error;
    }
  }

  setDefaultEmail() {
    const email = this.popup.getEmail();
    this.emailAddresssInput.setValue(email);
  }

  beforeSubmit() {
    this.passwordContainer.verifyPasswordMatch();
  }

  onLoad() {
    this.setDefaultEmail();
    this.popup.setOfferClaimed();
  }

  onSuccess(responseText) {
    const errorMsg = this.getErrorMsg(responseText);
    if (errorMsg) {
      return this.popup.notifyError({ context: errorMsg });
    }

    this.popup.notifySuccess({ context: 'Account Created!' });
    const delayMs = 500;
    setTimeout(() => window.location.reload(), delayMs);
  }
}
