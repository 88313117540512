export const FKP_OPTION_CONTAINER_CLASS = 'fkp__option-container';
export const FKP_OFFER_TEXT_CONTAINER_CLASS = 'fkp__offer-text-container';
export const FKP_STAGE_MESSAGE_CLASS = 'fkp__stage-message';
export const FKP_STYLIZED_TEXT_CLASS = 'fkp__stylized-text';
export const FKP_OFFER_SELECTION_CONTAINER = 'fkp__offer-selection-container';
export const FKP_CLAIM_EMAIL_TEXT_CLASS = 'fkp__claim-email-text';
export const FKP_CODE_CONTAINER_CLASS = 'fkp__code-container';
export const FKP_CODE_TITLE_CLASS = 'fkp__code-title';
export const FKP_CODE_LINE_CLASS = 'fkp__code-line';
export const FKP_CODE_CLASS = 'fkp__code';
export const FKP_ORDER_DETAIL_CLASS = 'order__detail';
export const FKP_ORDER_DETAIL_VALUE_CLASS = 'order__detail-value';
export const FKP_ORDER_DETAIL_HEADER_CLASS = 'fkp__order__details-header';
export const FREE_KRATOM_OFFER = {
  TITLE: 'Free Kratom!',
  SUBTITLE: 'One-Time Offer',
};
export const DISCOUNT_OFFER = {
  TITLE: '15% Off!',
  SUBTITLE: 'Your First Purchase',
};
export const FKP_STAGE_FORM_HEADER_CLASS = 'fkp__stage-form-header';
export const FKP_STAGE_TITLE_CLASS = 'fkp__stage-title';
export const FKP_STAGE_SUBTITLE_CLASS = 'fkp__stage-subtitle';
export const FKP_STAGE_HEADER_CLASS = 'fkp__stage-header';
