import { PhoneNumberInput, SubmitButton } from '../components/form/field/fields.js';
import { FKS_ENDPOINT_SMS_SUBMIT, FORM_KEY_SMS_NUMBER, STAGES } from '../constants.js';
import StageForm from './StageForm';
import { formatSmsNumber, injectEntryInFormData } from '../utils.js';
import { HTTP_METHOD_POST } from './stageFormConstants';

const SHOPIFY_CUSTOMER_ID_FORM_KEY = 'shopify_customer_id';

export default class Stage1Form extends StageForm {
  constructor(popup = {}) {
    const urlDetails = {
      baseUrl: popup.apiBaseUrl,
      action: FKS_ENDPOINT_SMS_SUBMIT,
      method: HTTP_METHOD_POST,
    };
    const formOptions = {
      noValidate: true,
    };
    const formProps = {
      popup: popup,
      urlDetails: urlDetails,
      formOptions: formOptions,
    };
    super(formProps);
    this.children = this.buildChildren();
  }

  buildChildren() {
    const phoneNumberInput = PhoneNumberInput();
    const submitButton = SubmitButton();
    return [phoneNumberInput.html, submitButton.html];
  }

  updateFormDataSmsNumber(formData, smsNumber) {
    formData.set(FORM_KEY_SMS_NUMBER, smsNumber);
  }

  beforeSubmit(popupForm) {
    const { formData } = popupForm;
    const smsNumber = this.getSmsNumberFromFormData(formData);
    this.formatFormDataSms(formData, smsNumber);
    this.popup.setSmsNumber(smsNumber);

    const customerId = this.popup.getShopifyCustomerId();
    if (customerId) {
      injectEntryInFormData(SHOPIFY_CUSTOMER_ID_FORM_KEY, customerId, formData);
    }
  }

  getSmsNumberFromFormData(formData) {
    return formData.get(FORM_KEY_SMS_NUMBER);
  }

  formatFormDataSms(formData, smsNumber) {
    const formattedSmsNumber = formatSmsNumber(smsNumber);
    if (smsNumber && formattedSmsNumber) {
      this.updateFormDataSmsNumber(formData, formattedSmsNumber);
    }
  }

  onSuccess(data = {}) {
    const { session_uuid: sessionUuid } = data;
    if (!sessionUuid) {
      return this.onError({
        status: 'Error 2107',
        context: 'Invalid session. Please try again later.',
      });
    }
    this.updatePopupState(sessionUuid);
  }

  updatePopupState(sessionUuid) {
    this.popup.setSessionUuid(sessionUuid);
    this.popup.setStage(STAGES.STAGE_2);
  }
}
