import { FKP_BUTTON_CLASS, FKP_BUTTON_PLAIN_CLASS } from '../../../../constants';
import { FORM_FIELD_CLASSES, FORM_FIELD_ELEMENT_TYPES } from '../../formConstants';

const FKP_SMS_CODE_LINK_CLASS = 'fkp__resend-sms-code-link';
const RESEND_COUNTDOWN_SECONDS = 30;

export default class SMSVerificationLink {
  html = null;
  button = null;

  BUTTON_CLASSES = [FKP_BUTTON_CLASS, FKP_BUTTON_PLAIN_CLASS];

  props = {
    countdown: RESEND_COUNTDOWN_SECONDS,
    countText: `Didn't receive a code? Send another code in %d seconds.`,
    freeText: 'Resend Code',
    onClick: () => {},
  };

  state = {
    interval: null,
    enabled: false,
    secondsLeft: 0,
  };

  constructor(props) {
    Object.assign(this.props, props);
    this.create();
    this.html.addEventListener('click', this.onClick);
  }

  start = () => {
    clearInterval(this.state.interval);
    this.state.secondsLeft = this.props.countdown;
    this.updateText();
    const DELAY_MS = 1000;
    this.state.interval = setInterval(this.tick, DELAY_MS);
  };

  tick = () => {
    this.state.secondsLeft -= 1;
    const secondsLeft = this.state?.secondsLeft;

    this.updateText();

    if (parseInt(secondsLeft) <= 0) {
      clearInterval(this.state.interval);
    }
  };

  updateText = () => {
    const secondsLeft = this.state?.secondsLeft;

    if (secondsLeft > 0) {
      this.disable();
      this.button.innerText = this.props.countText.replace('%d', secondsLeft);
    } else {
      this.enable();
      this.button.innerText = this.props.freeText;
    }
  };

  disable = () => {
    this.button.toggleAttribute('disabled', true);
    this.state.enabled = false;
  };

  enable = () => {
    this.button.toggleAttribute('disabled', false);
    this.state.enabled = true;
  };

  create = () => {
    this.html = document.createElement(FORM_FIELD_ELEMENT_TYPES.FIELD);
    this.html.classList.add(FORM_FIELD_CLASSES.FIELD, FKP_SMS_CODE_LINK_CLASS);

    const wrapper = document.createElement(FORM_FIELD_ELEMENT_TYPES.WRAPPER);
    wrapper.classList.add(FORM_FIELD_CLASSES.WRAPPER);
    this.html.append(wrapper);

    this.button = document.createElement('button');
    this.button.classList.add(...this.BUTTON_CLASSES);
    wrapper.append(this.button);
  };

  onClick = () => {
    if (!this.state.enabled) {
      return;
    }
    this.props.onClick();
    this.start();
  };
}
