import { FORM_FIELD_ELEMENT_TYPES } from '../formConstants';

export default class FormFieldLabel {
  html = null;

  constructor(props) {
    this.create(props);
  }

  create = (props) => {
    this.props = props;
    this.html = document.createElement(FORM_FIELD_ELEMENT_TYPES.LABEL);
    this.html.for = this.props.id;
    this.html.innerText = this.props.label;
    this.html.classList.add('label');
  };
}
