import { FORM_FIELD_CLASSES } from '../formConstants';

const ADDRESS_HEADER_TEXT = 'Shipping Address';
const ORDER_SUMMARY_CLASS = 'fkp__order-summary';
const ORDER_SUMMARY_DETAILS_CLASS = 'fkp__order-summary__details';

export default class OrderSummaryTemplate {
  html = null;
  deliveryDetailsHtml = null;
  constructor() {
    this.create();
  }

  insertInformation = (address) => {
    this.deliveryDetailsHtml.innerHTML = this.getDeliveryDetailsInnerHtml(address);
  };

  create = () => {
    this.html = document.createElement('div');
    this.html.classList.add(FORM_FIELD_CLASSES.FIELD, ORDER_SUMMARY_CLASS);

    const deliveryDetailsHeader = document.createElement('h3');
    deliveryDetailsHeader.innerText = ADDRESS_HEADER_TEXT;

    this.deliveryDetailsHtml = document.createElement('div');
    this.deliveryDetailsHtml.classList.add(ORDER_SUMMARY_DETAILS_CLASS);

    this.html.append(deliveryDetailsHeader, this.deliveryDetailsHtml);
  };

  getDeliveryDetailsInnerHtml = (address) => {
    const {
      first_name: firstName = 'First Name',
      last_name: lastName = 'Last Name',
      address1 = 'Address Line 1',
      address2,
      city = 'City',
      province = 'State/Province',
      zip_code: zipCode = 'Zip/Postal Code',
      country = 'Country',
    } = address;
    return `
      ${firstName} ${lastName}<br>
      ${address1} ${address2 + ' ' ? address2 : ''} ${city}, ${province} ${zipCode}<br>
      ${country}
    `;
  };
}
