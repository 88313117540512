export default class StageBuilder {
  /**
   * @param {typeof Stage} StageClass
   * @param {FKPPopup} popup
   * @return {Stage}
   */
  static buildStage(StageClass, popup) {
    const stage = new StageClass(popup);
    stage.form = stage.buildForm();
    stage.container = stage.buildStageContainer(stage.form);
    return stage;
  }

  /**
   * @return Stage[]
   * @param {FKPPopup} popup
   * @param {typeof Stage[]} stageClasses
   */
  static createStagesForPopup(popup, stageClasses) {
    return stageClasses.map((StageClass) => StageBuilder.buildStage(StageClass, popup));
  }
}
