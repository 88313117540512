import {
  FORM_KEY_ADDRESS,
  CA_PROVINCE_OPTIONS,
  DOMPARSER_PARSE_ERROR_SELECTOR,
  EMAIL_ADDRESS_PATTERN,
  FKP_FORM_FIELD_GROUP_CLASS,
  PHONE_NUMBER_PATTERN,
  FORM_KEY_SESSION_UUID,
  US_STATE_OPTIONS,
} from './constants';
import Container from './components/Container';

/**
 * Formats a phone number like this: (123) 555-6789
 * @param {string} value the phone number string
 * @returns {string} the formatted phone number string
 */
export function getFormattedPhoneNumber(value) {
  if (!value) {
    return value;
  }
  value = value.replace(/\D/g, '');
  const length = value.length;
  if (length < 4) {
    return value;
  }
  if (length < 7) {
    return `(${value.slice(0, 3)}) ${value.slice(3)}`;
  }
  return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
}

export function getEncodedPayload(formData) {
  const output = [];
  for (const [key, value] of formData.entries()) {
    const k = encodeURIComponent(key);
    const v = encodeURIComponent(value);
    output.push(`${k}=${v}`);
  }
  return output.join('&');
}

export async function decipherResponse(response) {
  let parsed = await response.text();
  try {
    parsed = JSON.parse(parsed);
  } catch {}
  switch (response.status) {
    case 200:
      return parsed;
    default:
      throw {
        status: parsed.status,
        context: parsed.context,
      };
  }
}

export function getStateProvinceOptions(countryCode) {
  let options = null;
  switch (countryCode) {
    case 'US':
      options = US_STATE_OPTIONS;
      break;
    case 'CA':
      options = CA_PROVINCE_OPTIONS;
      break;
  }
  return options;
}

export function injectEntryInFormData(key, value, formData) {
  if (!key || !value || !formData) {
    console.error('key, value, or formData undefined');
    return;
  }
  formData.append(key, value);
}

export function injectSessionInFormData(sessionUuid, formData) {
  if (!sessionUuid || !formData) {
    console.error('sessionUuid or formData undefined');
    return;
  }
  injectEntryInFormData(FORM_KEY_SESSION_UUID, sessionUuid, formData);
}

export function injectReferrerUrlInFormData(formData) {
  if (!formData) {
    console.error('formData undefined');
    return;
  }
  injectEntryInFormData('referrer_url', document.referrer, formData);
}

export function clamp(value, min, max) {
  return Math.max(min, Math.min(value, max));
}

export function formatSmsNumber(smsNumber) {
  const cleanedSmsNumber = smsNumber?.replace?.(/\D/g, '');
  return `+1${cleanedSmsNumber}`;
}

export function isSmsNumberValid(smsNumber) {
  return PHONE_NUMBER_PATTERN.test(smsNumber);
}

export function isEmailValid(email) {
  return EMAIL_ADDRESS_PATTERN.test(email);
}

export function processPhoneNumber(phoneNumber) {
  const valid = isSmsNumberValid(phoneNumber);
  if (!valid) {
    return null;
  }
  return formatSmsNumber(phoneNumber);
}

export function processEmail(email) {
  const valid = isEmailValid(email);
  if (!valid) {
    return null;
  }
  return email;
}

export function getLoggedInCustomer() {
  const dataLayerBase = window.dataLayer?.[0];
  return dataLayerBase?.customer;
}

export function parseHtmlFromString(htmlString) {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(htmlString, 'text/html');
  const errorNode = parsedHtml.querySelector(DOMPARSER_PARSE_ERROR_SELECTOR);
  if (errorNode) {
    throw new Error('Error during parsing html.');
  }
  return parsedHtml;
}

export function getLastCharacters(charString, numberOfChars) {
  const length = charString?.length;
  return charString?.substring?.(length - numberOfChars);
}

export function writeToClipboard(text) {
  const length = text?.length;
  if (!length) {
    throw new Error('text in writeToClipboard must be a valid string');
  }
  navigator.clipboard.writeText(text);
}

export function createFormContainerWithChildren(children) {
  return new Container({
    classes: [FKP_FORM_FIELD_GROUP_CLASS],
    children: [...children],
  });
}

export function parseAddressFromFormData(formData) {
  if (!formData) {
    return {};
  }

  const addressKeysValues = Object.entries(FORM_KEY_ADDRESS);
  return addressKeysValues.reduce(assignAddressValues(formData), {});
}

export function assignAddressValues(formData) {
  return (address, [key, formKey]) => {
    address[key] = formData?.get?.(formKey) ?? '';
    return address;
  };
}

// Doesn't handle nested objects, array, etc.
export function convertObjToQueryString(queryParamsObj) {
  if (queryParamsObj == null) {
    return '';
  }
  const validParams = filterValidQueryParams(queryParamsObj);
  const queryParamsString = validParams.map(encodeKeyValuePair).join('&');
  return queryParamsString;
}

function filterValidQueryParams(queryParamsObj) {
  const entries = Object.entries(queryParamsObj);
  const validEntries = entries.filter(([key, value]) => {
    return value != null && value !== '';
  });
  return validEntries;
}

function encodeKeyValuePair([key, value]) {
  const encodedKey = encodeURIComponent(key);
  const encodedValue = encodeURIComponent(value);
  return `${encodedKey}=${encodedValue}`;
}

export function stripTrailingSlash(inputString) {
  const TRAILING_SLASH_PATTERN = /\/$/;
  return inputString.replace(TRAILING_SLASH_PATTERN, '');
}

export function createStrippedUrlString(inputString) {
  const urlString = new URL(inputString).toString();
  return stripTrailingSlash(urlString);
}

export function isScreenSmall() {
  const SMALL_SCREEN_SIZE_PX = 550;
  return window.innerWidth <= SMALL_SCREEN_SIZE_PX;
}

export function hideZendeskButton() {
  if (!isScreenSmall()) {
    return;
  }

  const zendeskButtonHtml = getZendeskButtonElement();
  if (zendeskButtonHtml) {
    zendeskButtonHtml.style.display = 'none';
  }
}

export function showZendeskButton() {
  if (!isScreenSmall()) {
    return;
  }

  const zendeskButtonHtml = getZendeskButtonElement();
  if (zendeskButtonHtml) {
    zendeskButtonHtml.style.display = 'initial';
  }
}

export function getZendeskButtonElement() {
  const ZENDESK_BUTTON_IFRAME_HTML_SELECTOR = 'iframe#launcher';
  const zendeskIframe = document.querySelector(ZENDESK_BUTTON_IFRAME_HTML_SELECTOR);
  const htmlElement = zendeskIframe?.contentWindow?.document?.children?.[0];
  return htmlElement;
}
