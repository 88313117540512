import {
  FKP_ICON_BUTTON_CLASS,
  FKS_ENDPOINT_ADDRESS_SUGGEST,
  FKS_ENDPOINT_ADDRESS_ZIP,
  FORM_KEY_SESSION_UUID,
} from '../../../../constants';
import IconPoweredGoogle from '../../../icon/IconPoweredGoogle';
import IconX from '../../../icon/IconX';
import { FORM_FIELD_CLASSES } from '../../formConstants';

const INPUT_SELECTOR = `.${FORM_FIELD_CLASSES.INPUT}`;
const ADDRESS_1_WRAPPER_SELECTOR = `.${FORM_FIELD_CLASSES.WRAPPER}`;
const INPUT_SUGGEST_DELAY_MS = 150;

const CLASSES = {
  BOX: 'combo-box',
  CONTENT: 'combo-box__content',
  HEADER: 'combo-box__header',
  HIDDEN: 'combo-box--hidden',
  OPTION: 'combo-box__option',
  OPTION_SELECTED: 'combo-box__option--selected',
  TITLE: 'combo-box__header__title',
  ARIA_LIVE_REIGON: 'combo-box__aria-live',
  HIDDEN: 'hidden',
  OPTIONS: 'combo-box__options',
  FOOTER: 'combo-box__footer',
};

export default class StreetAutoCompleteComboBox {
  html = null;
  comboBoxCloseButton = null;
  comboBoxOptions = null;
  comboBoxAriaLive = null;

  inputs = null;
  updateSuggestionsTimeout = null;

  state = {
    predictions: [],
    selectedPredictionIndex: 0,
  };

  /**
   *
   * @param {FKPPopup} popup
   * @param {Object} addressElements
   * @param {HTMLElement} addressElements.address1
   * @param {HTMLElement} addressElements.city
   * @param {HTMLElement} addressElements.country
   * @param {HTMLElement} addressElements.province
   * @param {HTMLElement} addressElements.postalCode
   */
  constructor(popup, addressElements) {
    this.popup = popup;
    const address1Wrapper = this.getAddress1WrapperElement(addressElements);
    this.inputs = this.getInputsFromAddressElements(addressElements);
    this.initialize();
    address1Wrapper.appendChild(this.html);
    this.addEventListeners();
  }

  getAddress1WrapperElement = (addressElements) => {
    const addressElement = addressElements?.address1;
    if (!addressElement) {
      console.error('address1 element is undefined');
    }
    return addressElement.querySelector?.(ADDRESS_1_WRAPPER_SELECTOR);
  };

  getInputsFromAddressElements = (addressElements) => {
    const addressElementsEntries = Object.entries(addressElements);
    const addressInputsEntries = addressElementsEntries.map(this.transformElementToInputEntry);
    return Object.fromEntries(addressInputsEntries);
  };

  transformElementToInputEntry = ([addressKey, addressElement]) => {
    const addressInput = addressElement.querySelector?.(INPUT_SELECTOR);
    return [addressKey, addressInput];
  };

  updateSuggestions = async (addressValue) => {
    this.state.predictions = await this.fetchPredictions(addressValue);
    const predictionsLength = this.state.predictions?.length ?? 0;
    if (predictionsLength < 1) {
      this.hide();
      return;
    }

    this.show();
    this.state.selectedPredictionIndex = 0;
    this.selectPrediction(this.state.selectedPredictionIndex);
  };

  populateFieldValues = async (prediction) => {
    if (!prediction) {
      return;
    }

    const { structured_formatting: structuredFormatting, place_id: placeId } = prediction;

    if (structuredFormatting) {
      const { main_text: mainText, secondary_text: secondaryText } = structuredFormatting;
      if (mainText) {
        this.inputs.address1.value = mainText;
      }

      const secondaryParts = secondaryText?.split?.(',')?.map?.((s) => s.trim?.());
      for (const input of [this.inputs.country, this.inputs.province, this.inputs.city]) {
        if (secondaryParts.length === 0) {
          break;
        }
        let value = secondaryParts.pop();
        if (value === 'USA') {
          value = 'US';
        } // why does google return country as USA?
        // Don't autofill banned state
        if (input === this.inputs.province) {
          const targetOption = input.querySelector(`option[value="${value}"]`);
          const isRestrictedProvince = targetOption.disabled;
          if (isRestrictedProvince) {
            continue;
          } // skip filling this field.
        }
        input.value = value;
      }
    }

    if (placeId) {
      const postalCode = await this.fetchPostalCode(placeId);
      this.inputs.postalCode.value = postalCode ?? '';
    }
  };

  choosePrediction = (index) => {
    this.selectPrediction(index);
    this.hide();
    const prediction = this.state.predictions[index];
    this.populateFieldValues(prediction);
  };

  selectNextPrediction = () => {
    const { predictions, selectedPredictionIndex } = this.state;
    const nextIndex = (selectedPredictionIndex + 1) % predictions.length;
    this.selectPrediction(nextIndex);
  };

  selectPreviousPrediction = () => {
    const { predictions, selectedPredictionIndex } = this.state;
    const previousIndex = selectedPredictionIndex - 1 < 0 ? predictions.length - 1 : selectedPredictionIndex - 1;
    this.selectPrediction(previousIndex);
  };

  selectPrediction = (index) => {
    this.state.selectedPredictionIndex = index;
    this.updateComboBox();
    this.updateAriaLiveText();
  };

  fetchPredictions = async (searchText) => {
    if (!searchText?.length || searchText.legnth < 1) {
      return;
    }

    const url = this.popup.apiBaseUrl + FKS_ENDPOINT_ADDRESS_SUGGEST;
    const sessionUuid = this.popup.getSessionUuid();
    const options = {
      method: 'post',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Accept': 'application/json',
      },
      body: JSON.stringify({ [FORM_KEY_SESSION_UUID]: sessionUuid, address: searchText }),
    };
    try {
      const response = await fetch(url, options);
      const json = await response.json();
      const predictions = json.predictions ?? [];
      this.state.session_token = json.session_token ?? null;
      return predictions;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  fetchPostalCode = async (placeId) => {
    if (!placeId) {
      return null;
    }
    const url = this.popup.apiBaseUrl + FKS_ENDPOINT_ADDRESS_ZIP;
    const sessionUuid = this.popup.getSessionUuid();
    const options = {
      method: 'post',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Accept': 'application/json',
      },
      body: JSON.stringify({ [FORM_KEY_SESSION_UUID]: sessionUuid, place_id: placeId }),
    };
    try {
      const response = await fetch(url, options);
      const json = await response.json();
      const zipCode = json.zip_code;
      return zipCode ?? null;
    } catch {
      return null;
    }
  };

  updateComboBox = () => {
    this.comboBoxOptions.innerHTML = '';
    const optionNodes = this.createComboBoxOptionNodes();
    if (!optionNodes?.length || optionNodes.length === 0) {
      this.hide();
      return;
    }

    this.comboBoxOptions.append(...optionNodes);
    this.show();
  };

  createComboBoxOptionNodes = () => {
    const { predictions = [] } = this.state;
    return predictions.map((prediction, index) => {
      return this.createComboBoxOption(prediction, index);
    });
  };

  updateAriaLiveText = () => {
    const { selectedPredictionIndex, predictions } = this.state;
    if (predictions.length === 0) {
      this.comboBoxAriaLive.textContent = '';
      return;
    }
    const selectedPrediction = predictions[selectedPredictionIndex];
    const selectedAddressText = `${selectedPrediction.description}`;
    const positionInListText = `${selectedPredictionIndex + 1} of ${predictions.length}`;
    // const positionInListText = selectedPredictionIndex + 1 + ' of ' + predictions.length;
    const ariaLiveText = `${selectedAddressText} - ${positionInListText}`;
    this.comboBoxAriaLive.textContent = ariaLiveText;
  };

  createComboBoxOption = (prediction, index) => {
    const { description, matched_substrings: matchedSubstrings } = prediction;

    const li = document.createElement('li');
    li.id = index;
    li.classList.add(CLASSES.OPTION);
    li.setAttribute('role', 'option');
    const isSelected = index === this.state.selectedPredictionIndex;
    if (isSelected) {
      li.setAttribute('aria-selected', isSelected);
      li.classList.add(CLASSES.OPTION_SELECTED);
    }

    const content = document.createElement('div');
    content.classList.add(CLASSES.CONTENT);
    content.dataset.index = index;
    content.append(...this.getEmphasizedText(description, matchedSubstrings));

    li.append(content);

    return li;
  };

  getEmphasizedText = (text, matchedSubstrings) => {
    const nodes = [];
    let characterIndex = 0;
    let matchSubstringIndex = 0;
    while (characterIndex < text.length) {
      const match = matchedSubstrings[matchSubstringIndex];
      if (match && match.offset === characterIndex) {
        const start = match.offset;
        const end = start + match.length;
        const highlightedText = text.slice(start, end);
        nodes.push(this.createEmphasizedText(highlightedText));
        characterIndex = end;
        matchSubstringIndex++;
      } else {
        nodes.push(document.createTextNode(text[characterIndex]));
        characterIndex++;
      }
    }

    return nodes;
  };

  createEmphasizedText = (text) => {
    const span = document.createElement('span');
    span.classList.add('emphasis');
    span.textContent = text;
    return span;
  };

  show = () => {
    setTimeout(() => this.html.classList.remove(CLASSES.HIDDEN));
  };

  hide = () => {
    setTimeout(() => this.html.classList.add(CLASSES.HIDDEN));
  };

  initialize = () => {
    this.initializeComboBox();
    this.initializeHeader();
    this.initializeHeaderTitle(this.header);
    this.initializeCloseButton(this.header);
    this.initializeComboBoxOptions();
    this.initializeComboBoxAriaLive();
    this.initializeComboBoxFooter();
  };

  initializeComboBox = () => {
    this.html = document.createElement('div');
    this.html.classList.add(CLASSES.BOX);
    this.hide();
  };

  initializeHeader = () => {
    this.header = document.createElement('div');
    this.header.classList.add(CLASSES.HEADER);
    this.html.appendChild(this.header);
  };

  initializeHeaderTitle = (header) => {
    const headerTitle = document.createElement('h3');
    headerTitle.classList.add(CLASSES.TITLE);
    headerTitle.innerText = 'Suggestions';
    header.appendChild(headerTitle);
  };

  initializeCloseButton = (header) => {
    this.comboBoxCloseButton = document.createElement('button');
    this.comboBoxCloseButton.classList.add(FKP_ICON_BUTTON_CLASS);
    this.comboBoxCloseButton.type = 'hidden';
    this.comboBoxCloseButton.ariaLabel = 'Close suggestions';
    this.comboBoxCloseButton.dataset.ignore = true;

    const svg = IconX();
    svg.setAttribute('focusable', false);
    this.comboBoxCloseButton.appendChild(svg);

    header.appendChild(this.comboBoxCloseButton);
  };

  initializeComboBoxAriaLive = () => {
    this.comboBoxAriaLive = document.createElement('div');
    this.comboBoxAriaLive.classList.add(CLASSES.HIDDEN, CLASSES.ARIA_LIVE_REIGON);
    this.comboBoxAriaLive.setAttribute('aria-live', 'polite');
    this.comboBoxAriaLive.setAttribute('aria-atomic', 'true');
    this.html.appendChild(this.comboBoxAriaLive);
  };

  initializeComboBoxOptions = () => {
    this.comboBoxOptions = document.createElement('ul');
    this.comboBoxOptions.classList.add(CLASSES.OPTIONS);
    this.comboBoxOptions.setAttribute('role', 'listbox');
    if (this.inputs.ariaOwns) {
      this.comboBoxOptions.id = this.inputs.ariaOwns;
    }
    this.html.appendChild(this.comboBoxOptions);
  };

  initializeComboBoxFooter = () => {
    const comboBoxFooter = document.createElement('div');
    comboBoxFooter.classList.add(CLASSES.FOOTER);
    comboBoxFooter.appendChild(IconPoweredGoogle());
    this.html.appendChild(comboBoxFooter);
  };

  addEventListeners = () => {
    this.inputs.address1.addEventListener('keydown', this.onKeyDown);
    this.inputs.address1.addEventListener('click', this.onMouseDown);
    this.inputs.address1.addEventListener('blur', this.onBlur);
    this.html.addEventListener('mousedown', this.onMouseDown);
    this.html.addEventListener('pointerup', this.onMouseDown);
    this.html.addEventListener('ontouchstart', this.onMouseDown);
    this.comboBoxOptions.addEventListener('mouseover', this.onMouseOver);
    // this.comboBoxOptions.addEventListener('ontouchstart', this.onMouseDown);
  };

  onBlur = (event) => {
    // event.preventDefault();
    clearTimeout(this.updateSuggestionsTimeout);
    this.hide();
  };

  onKeyDown = (event, pasted) => {
    clearTimeout(this.updateSuggestionsTimeout);
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        this.choosePrediction(this.state.selectedPredictionIndex);
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.selectPreviousPrediction();
        break;
      case 'ArrowDown':
        event.preventDefault();
        this.selectNextPrediction();
        break;
      default:
        this.updateSuggestionsTimeout = setTimeout(async () => {
          const addressValue = event.target?.value?.trim?.();
          await this.updateSuggestions(addressValue);
        }, INPUT_SUGGEST_DELAY_MS);
    }
  };

  onMouseDown = (event) => {
    const { target } = event;
    event.preventDefault();
    this.inputs?.address1?.focus?.();
    if (target === this.comboBoxCloseButton) {
      this.hide();
      return;
    }

    if (target === this.inputs.address1) {
      const { predictions = [] } = this.state;
      predictions.length > 0 ? this.show() : this.hide();
    }

    const index = this.getOptionIndexFromEvent(event, true);
    if (index != null) {
      this.choosePrediction(index);
    }
  };

  onMouseOver = (event) => {
    const index = this.getOptionIndexFromEvent(event);
    if (!index && index !== 0) {
      return;
    }

    if (index !== this.state.selectedPredictionIndex) {
      this.selectPrediction(index);
    }
  };

  getOptionIndexFromEvent = (event, ismousedown) => {
    const { target } = event;
    const index = parseInt(target?.dataset?.index);
    return isNaN(index) ? null : index;
  };
}
