import { getFormattedPhoneNumber, getLastCharacters, getStateProvinceOptions } from '../../../utils.js';
import FormButton from './FormButton.js';
import OTCInput from './OTCInput.js';
import {
  BASE_SMS_MESSAGE,
  EMAIL_ADDRESS_PATTERN,
  FKP_CLAIM_TYPE,
  FKP_EVENT,
  FKP_FORM_FIELD_GROUP_CLASS,
  FORM_KEY_SMS_NUMBER,
  PHONE_NUMBER_PATTERN,
} from '../../../constants.js';
import Container from '../../Container.js';
import FormField from './FormField.js';
import IconCopy from '../../icon/IconCopy.js';
import ImageContainer from '../../ImageContainer.js';
import SingleText from '../../SingleText';
import { FORM_FIELD_CLASSES } from '../formConstants';
import {
  DISCOUNT_OFFER,
  FKP_CLAIM_EMAIL_TEXT_CLASS,
  FKP_CODE_CONTAINER_CLASS,
  FKP_CODE_LINE_CLASS,
  FKP_CODE_TITLE_CLASS,
  FKP_OFFER_SELECTION_CONTAINER,
  FKP_OFFER_TEXT_CONTAINER_CLASS,
  FKP_OPTION_CONTAINER_CLASS,
  FKP_ORDER_DETAIL_CLASS,
  FKP_ORDER_DETAIL_HEADER_CLASS,
  FKP_ORDER_DETAIL_VALUE_CLASS,
  FKP_STAGE_FORM_HEADER_CLASS,
  FKP_STAGE_HEADER_CLASS,
  FKP_STAGE_MESSAGE_CLASS,
  FKP_STAGE_SUBTITLE_CLASS,
  FKP_STAGE_TITLE_CLASS,
  FKP_STYLIZED_TEXT_CLASS,
  FREE_KRATOM_OFFER,
} from './formFieldConstants';
import Button from '../../Button';

export function SubmitButton(text) {
  return new FormButton({
    text: text ?? 'Submit',
    level: 'primary',
    type: 'submit',
  });
}

export function SelectDiscountButtonStage3(props) {
  return new FormButton({
    text: 'Select',
    level: 'primary',
    type: 'submit',
    ...props,
  });
}

export function SelectSamplesButtonStage3(props) {
  return new FormButton({
    text: 'Select',
    level: 'primary',
    type: 'submit',
    ...props,
  });
}

export function ContinueShoppingButtonStage6b(popup) {
  return new FormButton({
    text: 'Continue shopping',
    type: 'button',
    level: 'primary',
    onClick: () => {
      const isPopupCloseable = popup?.isFreeKratomPage() ?? false;
      if (!isPopupCloseable) {
        popup.setCookieLastDisplayed();
        window.location.href = '/';
      } else {
        popup.close();
      }
    },
  });
}

export function PhoneNumberInput() {
  return new FormField({
    autocomplete: 'shipping tel',
    errorId: 'error-for-phone',
    errorText: 'Enter a valid phone number',
    id: 'fkp__phone_number',
    label: 'Phone',
    name: FORM_KEY_SMS_NUMBER,
    pattern: PHONE_NUMBER_PATTERN,
    placeholder: 'Phone',
    required: true,
    size: '30',
    type: 'tel',
    classes: [FORM_FIELD_CLASSES.DOUBLE],
    onInput: (event) => {
      const { target } = event;
      const newValue = getFormattedPhoneNumber(target?.value);
      setTimeout(() => (target.value = newValue));
    },
  });
}

export function OneTimeCodeInput() {
  return new OTCInput();
}

export function FirstNameInput() {
  return new FormField({
    autocomplete: 'shipping given-name',
    autofocus: true,
    errorId: 'error-for-first_name',
    errorText: 'Enter a first name',
    id: 'fkp__first_name',
    label: 'First name',
    name: 'address[first_name]',
    placeholder: 'First name',
    required: true,
    size: '30',
  });
}

export function LastNameInput() {
  return new FormField({
    autocomplete: 'shipping family-name',
    errorId: 'error-for-last_name',
    errorText: 'Enter a last name',
    id: 'fkp__last_name',
    label: 'Last name',
    name: 'address[last_name]',
    placeholder: 'Last name',
    required: true,
    size: 30,
  });
}

export function AddressLine1Input() {
  return new FormField({
    autocomplete: 'shipping address-line1',
    errorId: 'error-for-address1',
    errorText: 'Enter an address',
    id: 'fkp__address1',
    label: 'Address',
    name: 'address[address1]',
    placeholder: 'Address',
    required: true,
    size: 30,
  });
}

export function AddressLine2Input() {
  return new FormField({
    autocomplete: 'shipping address-line2',
    id: 'fkp__address2',
    label: 'Apartment, suite, etc. (optional)',
    name: 'address[address2]',
    placeholder: 'Apartment, suite, etc. (optional)',
    size: '30',
  });
}

export function CityInput() {
  return new FormField({
    autocomplete: 'shipping address-level2',
    errorId: 'error-for-address_city',
    errorText: 'Enter an city',
    id: 'fkp__address_city',
    label: 'City',
    name: 'address[city]',
    placeholder: 'City',
    required: true,
    size: '30',
  });
}

export function CountryRegionSelect() {
  return new FormField({
    autocomplete: 'shipping country',
    errorId: 'error-for-country',
    errorText: 'Select a country/region',
    id: 'fkp__address_country',
    label: 'Country/region',
    name: 'address[country]',
    placeholder: 'Country/region',
    required: true,
    selectOptions: [{ value: 'US', innerText: 'United States' }],
    size: '1',
    type: 'select',
  });
}

export function StateProvinceSelect(countryCode) {
  return new FormField({
    autocomplete: 'shipping address-level1',
    errorId: 'error-for-province',
    errorText: 'Select a state / province',
    id: 'fkp__province',
    selectOptionsIncludePlaceholder: true,
    label: 'State/Province',
    name: 'address[province]',
    placeholder: 'State',
    required: true,
    selectOptions: getStateProvinceOptions(countryCode),
    size: '1',
    type: 'select',
  });
}

export function PostalCodeInput() {
  return new FormField({
    autocomplete: 'shipping postal-code',
    errorId: 'error-for-address_postal_code',
    errorText: 'Enter a ZIP / postal code',
    id: 'fkp__address_postal_code',
    label: 'Zip/Postal Code',
    name: 'address[zip_code]',
    placeholder: 'Zip/Postal Code',
    required: true,
    type: 'numeric',
  });
}

export function EmailAddressInput(id = 'fkp__email', name = 'email_address') {
  const emailAddressInput = new FormField({
    autocomplete: 'shipping email',
    errorId: 'error-for-email',
    errorText: 'Enter a valid email',
    id: id,
    label: 'Email',
    name: name,
    pattern: EMAIL_ADDRESS_PATTERN,
    placeholder: 'Email',
    required: true,
    type: 'email',
  });
  return emailAddressInput;
}

export function PasswordInput(name = 'password') {
  return new FormField({
    autocomplete: 'new-password',
    errorId: 'error-for-password',
    errorText: 'Enter a valid password',
    id: 'new-password',
    label: 'Password',
    name: name,
    placeholder: 'Password',
    required: true,
    type: 'password',
  });
}

export function VerifyPasswordInput() {
  return new FormField({
    autocomplete: 'new-password',
    errorId: 'error-for-verify-password',
    errorText: 'Passwords must match',
    id: 'verify-password',
    label: 'Verify Password',
    name: 'verify-password',
    placeholder: 'Verify Password',
    required: true,
    type: 'password',
  });
}

export function KratomDiscountImage(props = {}) {
  const { assetUrl } = props;
  return new ImageContainer({
    sources: [
      { type: 'image/webp', srcset: `${assetUrl}/kratom-group.webp` },
      { type: 'image/jpeg', srcset: `${assetUrl}/kratom-group.png` },
    ],
    src: `${assetUrl}/kratom-group.png`,
    alt: 'Kratom Pouches, Extracts, and Various Other Kratom Products',
    width: '416',
    height: '284',
    loading: 'lazy',
    ...props,
  });
}

export function KratomSampleImage(props = {}) {
  const { assetUrl } = props;
  return new ImageContainer({
    sources: [
      { type: 'image/webp', srcset: `${assetUrl}/kratom-samples.webp` },
      { type: 'image/jpeg', srcset: `${assetUrl}/kratom-samples.png` },
    ],
    src: `${assetUrl}/kratom-samples.png`,
    alt: 'Kratom Pouches',
    width: '340',
    height: '256',
    loading: 'lazy',
    ...props,
  });
}

export function KratomDiscountText() {
  const offerTitle = new SingleText({
    text: DISCOUNT_OFFER.TITLE,
    classes: [FKP_STAGE_TITLE_CLASS],
  });

  const offerDetails = new SingleText({
    text: DISCOUNT_OFFER.SUBTITLE,
    classes: [FKP_STAGE_SUBTITLE_CLASS],
  });

  return new Container({
    classes: [FKP_OFFER_TEXT_CONTAINER_CLASS],
    children: [offerTitle, offerDetails],
  });
}

export function KratomSampleText() {
  const offerTitle = new SingleText({
    text: FREE_KRATOM_OFFER.TITLE,
    classes: [FKP_STAGE_TITLE_CLASS],
  });

  const offerDetails = new SingleText({
    text: FREE_KRATOM_OFFER.SUBTITLE,
    classes: [FKP_STAGE_SUBTITLE_CLASS],
  });

  return new Container({
    classes: [FKP_OFFER_TEXT_CONTAINER_CLASS],
    children: [offerTitle, offerDetails],
  });
}

export function OptionOrText() {
  const optionOrText = new SingleText({
    text: 'Or',
    classes: [FKP_STAGE_TITLE_CLASS, FKP_STYLIZED_TEXT_CLASS],
  });
  return optionOrText;
}

export function DiscountOptionContainer(props) {
  const selectButton = SelectDiscountButtonStage3({
    formContainerElement: null,
    onClick: () => {
      const event = new CustomEvent(FKP_EVENT.SELECT_CLAIM_TYPE, {
        detail: FKP_CLAIM_TYPE.DISCOUNT,
        bubbles: true,
      });

      const { formContainerElement } = selectButton?.props ?? {};
      if (!formContainerElement) {
        console.error('formContainerElement is null, must be a valid element');
        return;
      }
      formContainerElement.dispatchEvent(event);
    },
  });

  const kratomDiscountText = KratomDiscountText();
  kratomDiscountText.html.appendChild(selectButton.html);

  const formContainer = new Container({
    classes: [FKP_OPTION_CONTAINER_CLASS],
    children: [KratomDiscountImage(props), kratomDiscountText],
  });
  selectButton.props.formContainerElement = formContainer.html;
  return formContainer;
}

export function SamplesOptionContainer(props) {
  const selectButton = SelectSamplesButtonStage3({
    onClick: () => {
      const event = new CustomEvent(FKP_EVENT.SELECT_CLAIM_TYPE, {
        detail: FKP_CLAIM_TYPE.SAMPLES,
        bubbles: true,
      });

      const { formContainerElement } = selectButton?.props ?? {};
      if (!formContainerElement) {
        console.error('formContainerElement is null, must be a valid element');
        return;
      }
      formContainerElement.dispatchEvent(event);
    },
  });

  const kratomSampleText = KratomSampleText();
  kratomSampleText.html.appendChild(selectButton.html);

  const formContainer = new Container({
    classes: [FKP_OPTION_CONTAINER_CLASS],
    children: [KratomSampleImage(props), kratomSampleText],
  });
  selectButton.props.formContainerElement = formContainer.html;
  return formContainer;
}

export function ClaimTypeInput() {
  return new FormField({
    name: 'claim_type',
    required: true,
    type: 'hidden',
  });
}

export function FullNameInputContainer() {
  return new Container({
    classes: [FKP_FORM_FIELD_GROUP_CLASS],
    children: [FirstNameInput(), LastNameInput()],
  });
}

export function AccountCreateText() {
  const offerDetails = new SingleText({
    text: 'Create an Account',
    classes: [FKP_STAGE_FORM_HEADER_CLASS],
  });
  return offerDetails;
}

export function FormTypeInput() {
  const formTypeInput = document.createElement('input');
  formTypeInput.name = 'form_type';
  formTypeInput.type = 'hidden';
  formTypeInput.value = 'create_customer';
  return { html: formTypeInput };
}

export function UtfInput() {
  const utfInput = document.createElement('input');
  utfInput.name = 'utf8';
  utfInput.type = 'hidden';
  utfInput.value = '✓';
  return { html: utfInput };
}

export function ClaimEmailText(content) {
  const offerDetails = new SingleText({
    text: content,
    classes: [FKP_CLAIM_EMAIL_TEXT_CLASS],
  });
  return offerDetails;
}
export function StageHeader(title, subtitle = null) {
  if (!title) {
    console.error('title must be present');
    return;
  }

  const children = [];
  const header = new SingleText({
    level: 'h2',
    text: title,
    classes: [FKP_STAGE_TITLE_CLASS],
  });
  children.push(header);

  if (subtitle) {
    const subheader = new SingleText({
      level: 'h3',
      text: subtitle,
      classes: [FKP_STAGE_SUBTITLE_CLASS],
    });
    children.push(subheader);
  }

  const textContainer = new Container({
    classes: [FKP_STAGE_HEADER_CLASS],
    children: children,
  });
  return textContainer;
}

export function KratomSampleContainer(imageProps = {}) {
  return new Container({
    classes: [FKP_OPTION_CONTAINER_CLASS],
    children: [KratomSampleImage(imageProps), KratomSampleText()],
  });
}

export function KratomDiscountContainer(imageProps = {}) {
  return new Container({
    classes: [FKP_OPTION_CONTAINER_CLASS],
    children: [KratomDiscountImage(imageProps), KratomDiscountText()],
  });
}

export function OrderNumberText(orderNumber) {
  const orderDetailsItem = document.createElement('div');
  const orderDetailsLabel = document.createElement('span');
  const orderDetailsValue = document.createElement('span');
  orderDetailsLabel.innerText = 'Order number: ';
  orderDetailsLabel.classList.add(FKP_ORDER_DETAIL_CLASS);
  orderDetailsValue.innerText = orderNumber;
  orderDetailsValue.classList.add(FKP_ORDER_DETAIL_VALUE_CLASS);
  orderDetailsItem.append(orderDetailsLabel);
  orderDetailsItem.append(orderDetailsValue);
  orderDetailsItem.classList.add(FKP_ORDER_DETAIL_HEADER_CLASS);
  return {
    html: orderDetailsItem,
    setText: (newText) => {
      orderDetailsValue.innerText = newText;
    },
  };
}

export function EmailText(emailAddress) {
  const confirmationMessageContainer = document.createElement('div');
  const confirmationMessage = document.createElement('span');
  const confirmationEmail = document.createElement('span');
  confirmationMessage.innerText = 'You will receive an order confirmation email shortly at ';
  confirmationMessage.classList.add(FKP_ORDER_DETAIL_CLASS);
  confirmationEmail.innerText = emailAddress;
  confirmationEmail.classList.add(FKP_ORDER_DETAIL_VALUE_CLASS);
  confirmationMessageContainer.append(confirmationMessage);
  confirmationMessageContainer.append(confirmationEmail);
  confirmationMessageContainer.classList.add(FKP_ORDER_DETAIL_HEADER_CLASS);
  return {
    html: confirmationMessageContainer,
    setText: (newText) => {
      confirmationEmail.innerText = newText;
    },
  };
}

export function SmsMessageText(smsNumber = '') {
  const NUMBER_OF_DIGITS = 4;
  const lastDigits = getLastCharacters(smsNumber, NUMBER_OF_DIGITS);
  let message = BASE_SMS_MESSAGE;
  if (lastDigits) {
    message += ` ending in ******${lastDigits}`;
  }

  const smsMessageText = document.createElement('p');
  smsMessageText.classList.add(FKP_STAGE_MESSAGE_CLASS);
  smsMessageText.innerText = message;

  return {
    html: smsMessageText,
    setText: (newText) => {
      smsMessageText.innerText = newText;
    },
    addEventListener: (eventName, handler) => {
      smsMessageText.addEventListener(eventName, handler);
    },
    dispatchEvent: (eventName, detail) => {
      smsMessageText.dispatchEvent(new CustomEvent(eventName, { detail }));
    },
  };
}

export function DiscountOrSamplesOptionContainer(props) {
  const formContainer = new Container({
    classes: [FKP_OFFER_SELECTION_CONTAINER],
    children: [DiscountOptionContainer(props), OptionOrText(), SamplesOptionContainer(props)],
  });
  return formContainer;
}

export function CodeTitle() {
  const codeTitle = document.createElement('span');
  codeTitle.innerText = 'Code:';
  codeTitle.classList.add(FKP_CODE_TITLE_CLASS);
  return { html: codeTitle };
}

export function CodeButton(code = 'Code') {
  const liveRegion = LiveRegion();
  const COPIED_MESSAGE = 'Code copied to clipboard!';
  const codeButton = new Button({
    classes: [FKP_CODE_LINE_CLASS],
    iconElement: IconCopy(),
    text: code,
    type: 'button',
    copyOnClick: true,
    onClick: () => {
      liveRegion.setText(COPIED_MESSAGE);
    },
  });

  const COPY_BUTTON_ARIA_LABEL = 'Copy code to clipboard';
  codeButton.html.ariaLabel = COPY_BUTTON_ARIA_LABEL;

  const container = new Container({
    classes: [FKP_CODE_CONTAINER_CLASS],
    children: [CodeTitle(), codeButton, liveRegion],
  });
  return {
    html: container.html,
    setText: (newText) => {
      codeButton.setText(newText);
    },
  };
}

export function LiveRegion() {
  const liveRegion = document.createElement('div');
  liveRegion.setAttribute('aria-live', 'polite');
  liveRegion.style.position = 'absolute';
  liveRegion.style.width = '1px';
  liveRegion.style.height = '1px';
  liveRegion.style.margin = '-1px';
  liveRegion.style.overflow = 'hidden';
  liveRegion.style.clipPath = 'rect(0, 0, 0, 0)';
  return {
    html: liveRegion,
    setText: (newText) => {
      liveRegion.innerText = newText;
    },
  };
}
