export default class Container {
  html = document.createElement('div');

  /**
   *
   * @param {Object} props the properties object for the image
   * @param {string[]} [props.classes] (Optional) Specifies the classes for the image element.
   */
  constructor(props) {
    this.props = props;
    this.initializeContainer();
  }

  initializeContainer = () => {
    if (this.props.classes) {
      this.html.classList.add(...this.props.classes);
    }

    this.props.children.forEach((child) => {
      this.html.appendChild(child.html);
    });
  };

  dispatchEvent = (eventName, detail) => {
    if (!this.html) {
      throw new Error('HTML element not initialized');
    }
    this.html.dispatchEvent(new CustomEvent(eventName, { detail }));
  };

  addEventListener = (eventName, handler) => {
    if (!this.html) {
      throw new Error('HTML element not initialized');
    }
    this.html.addEventListener(eventName, handler);
  };
}
