import StageForm from './StageForm.js';
import { injectReferrerUrlInFormData, injectSessionInFormData } from '../utils.js';
import { SubmitButton } from '../components/form/field/fields.js';
import OrderSummaryTemplate from '../components/form/template/OrderSummaryTemplate.js';
import { FKS_ENDPOINT_CLAIM_SAMPLES, STAGES } from '../constants.js';
import { HTTP_METHOD_POST } from './stageFormConstants';

export default class Stage5aForm extends StageForm {
  static SUBMIT_BUTTON_TEXT = 'Place Order';
  constructor(popup = {}) {
    const urlDetails = {
      baseUrl: popup.apiBaseUrl,
      action: FKS_ENDPOINT_CLAIM_SAMPLES,
      method: HTTP_METHOD_POST,
    };
    const formOptions = {
      noValidate: true,
    };
    const formProps = {
      popup: popup,
      urlDetails: urlDetails,
      formOptions: formOptions,
    };
    super(formProps);
    this.orderSummaryTemplate = new OrderSummaryTemplate(popup);
    this.children = this.buildChildren();
  }

  buildChildren() {
    const submitButton = SubmitButton(Stage5aForm.SUBMIT_BUTTON_TEXT);
    return [this.orderSummaryTemplate?.html, submitButton.html];
  }

  buildOrderDetailsFromData(data = {}) {
    return {
      order_id: data?.order_id ?? '',
      order_number: data?.order_number ?? '',
      order_status_url: data?.order_status_url ?? '',
    };
  }

  onLoad() {
    const address = this.popup.getAddress();
    this.orderSummaryTemplate.insertInformation(address);
  }

  beforeSubmit(popupForm) {
    const { formData } = popupForm;
    injectSessionInFormData(this.popup.getSessionUuid(), formData);
    injectReferrerUrlInFormData(formData);
  }

  onSuccess(data) {
    this.popup.setCookieLastDisplayed();
    const orderDetails = this.buildOrderDetailsFromData(data);
    this.popup.setOrderDetails(orderDetails);
    this.popup.setStage(STAGES.STAGE_6A);
  }
}
