import { getEncodedPayload, decipherResponse } from '../../utils.js';

const FKP_STAGE_FORM_CLASS = 'fkp__stage-form';
const LOADING_CLASS = 'loading';

export class PopupForm {
  html = document.createElement('form');
  formData = null;
  formSubmitterElement = null;

  /**
   * @param {Object} props The form properties object
   * @param {string} props.baseUrl The base url. (e.g. https://happy-hippo-free-kratom-system)
   * @param {string} props.method The form method. See [this page](https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/method) for more information.
   * @param {string} props.action The API endpoint path. (e.g. /public/get/configuration)
   * @param {HTMLElement[]} props.children An array of HTML elements to place insidethe form such as input fields, paragraphs, etc.
   * @param {Function} [props.beforeSubmit] Callback before the request is submitted. This popup form instance is passed as an argument.
   * @param {Function} [props.onSuccess] Callback when the response returns a 200 status code. The response data is passed as an argument.
   * @param {Function} [props.onError] Callback when the response returns a non 200 status code. The response data is passed as an argument.
   */
  constructor(props) {
    this.props = props;
    this.initializeForm();
  }

  initializeForm = () => {
    this.ref = this;
    this.html.addEventListener('submit', this.onSubmit);
    this.html.classList.add(FKP_STAGE_FORM_CLASS);

    if (this.props?.noValidate) {
      this.html.noValidate = true;
    }

    const classes = this.props?.classes ?? [];
    this.html.classList.add(...classes);

    const children = this.props.children ?? [];
    this.html.append(...children);
  };

  /**
   * Reports the validity of each field in the form, showing an error on those invalid.
   * @returns {boolean} whether all the form's fields are valid
   */
  reportValidityOnFields = () => {
    const elements = this.html.elements;
    for (const element of elements) {
      const valid = element.ref?.reportValidity?.() ?? true;
      if (!valid) {
        return false;
      }
    }
    return true;
  };

  /**
   * @returns {Object} the options payload for the fetch request upon form submission.
   */
  getSubmitRequestOptions = () => {
    const payload = this.createPayload();

    const options = {
      method: this.props.method,
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: payload,
    };

    return options;
  };

  createPayload = () => {
    return getEncodedPayload(this.formData);
  };

  /**
   * Handles the submission of a form. It first checks the validity of all fields,
   * reporting each field's validity by showing an error code.
   *
   * If all fields are valid, a request is then made to the api endpoint specified
   * in `props.action`. If a 200 status code is returned in the response, the
   * `props.onSuccess` callback will be invoked with the response data as the argument.
   *
   * If a non-status 200 code is returned in the response, the `props.onError` callback
   * will be invoked with the response data as the argument.
   *
   * @param {SubmitEvent} event the form submission event
   */
  onSubmit = async (event) => {
    event.preventDefault();

    const hasAllValidFields = this.reportValidityOnFields();
    if (!hasAllValidFields) {
      return;
    }

    this.formSubmitterElement = event.submitter;

    this.disableSubmitButton();
    try {
      this.formData = new FormData(this.html);
      this.props.beforeSubmit?.(this);
      const options = this.getSubmitRequestOptions();
      const { baseUrl, action } = this.props;
      const url = baseUrl + action;
      const response = await fetch(url, options);
      const decipheredResponse = await decipherResponse(response);
      this.props.onSuccess?.(decipheredResponse, this);
    } catch (error) {
      console.error(error);
      this.props.onError?.(error);
    } finally {
      const enableButtonDelayMs = 500;
      setTimeout(() => {
        this.enableSubmitButton();
      }, enableButtonDelayMs);
    }
  };

  disableSubmitButton = () => {
    const submitElement = this.formSubmitterElement;
    if (!submitElement) {
      return;
    }
    submitElement.disabled = true;
    submitElement.classList.add(LOADING_CLASS);
  };

  enableSubmitButton = () => {
    const submitElement = this.formSubmitterElement;
    if (!submitElement) {
      return;
    }
    submitElement.disabled = false;
    submitElement.classList.remove(LOADING_CLASS);
    submitElement.focus();
    submitElement.blur();
  };

  insertElementBeforeForm = (element) => {
    if (!element) {
      console.error('element must be valid to insert in form');
      return;
    }

    this.html.insertAdjacentElement('beforebegin', element);
  };
}
