import StageForm from './StageForm.js';
import { ContinueShoppingButtonStage6b, CodeButton } from '../components/form/field/fields.js';

export default class Stage6bForm extends StageForm {
  constructor(popup = {}) {
    const urlDetails = {};
    const formOptions = {
      noValidate: true,
    };
    const formProps = {
      popup: popup,
      urlDetails: urlDetails,
      formOptions: formOptions,
    };
    super(formProps);

    const discountCode = popup?.getDiscountCode?.();
    this.codeButton = CodeButton(discountCode);
    this.children = this.buildChildren(popup);
  }

  buildChildren(popup) {
    const continueShoppingButton = ContinueShoppingButtonStage6b(popup);
    return [this.codeButton.html, continueShoppingButton.html];
  }

  onLoad() {
    const discountCode = this.popup?.getDiscountCode?.();
    this.codeButton.setText(discountCode);
    this.popup.setOfferClaimed();
  }
}
