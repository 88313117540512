import { FKP_BUTTON_CLASS } from '../constants';
import { writeToClipboard } from '../utils';

export default class Button {
  html = document.createElement('button');
  innerSpan = document.createElement('span');
  icon = null;

  /**
   *
   * @param {{classes: string[], copyOnClick: boolean, text: string, type: string, iconElement: *}} props the properties object for the submit button
   * @param {string} [props.classes] (Optional) Classes.
   * @param {string} [props.iconElement] (Optional) Icon
   */
  constructor(props) {
    this.props = props;
    this.initialize();
    this.addEventListeners();
  }

  initialize = () => {
    this.initializeInnerSpan();
    this.initializeButton();
  };

  initializeButton = () => {
    const { iconElement, classes, type, name, id } = this.props;
    this.html.classList.add(FKP_BUTTON_CLASS);
    if (iconElement) {
      this.icon = iconElement;
      this.html.append(this.icon);
    }

    if (id) {
      this.html.id = id;
    }
    if (name) {
      this.html.name = name;
    }
    if (classes) {
      this.html.classList.add(...classes);
    }
    if (type) {
      this.html.type = type;
    }
  };

  initializeInnerSpan = () => {
    const { text } = this.props;
    if (text) {
      this.innerSpan.innerText = text;
    }
    this.html.append(this.innerSpan);
  };

  addEventListeners = () => {
    this.html.addEventListener('click', this.onClick);
  };

  onClick = () => {
    this.props.onClick?.();

    if (this.props.copyOnClick) {
      this.copyTextToClipboard();
    }
  };

  setText = (text) => {
    this.innerSpan.innerText = text;
  };

  getText = () => {
    return this.innerSpan.innerText;
  };

  copyTextToClipboard = () => {
    const text = this.getText();
    if (text?.length) {
      writeToClipboard(text);
    }
  };
}
