import StageForm from './StageForm.js';
import { injectSessionInFormData } from '../utils.js';
import { EmailAddressInput, SubmitButton } from '../components/form/field/fields.js';
import { FKS_ENDPOINT_EMAIL_VERIFY, STAGES } from '../constants.js';
import { HTTP_METHOD_POST } from './stageFormConstants';
import Notice from '../components/Notice';

const FKP_DISCOUNT_EMAIL_CLASS = 'fkp__discount-email';

export default class Stage4bForm extends StageForm {
  constructor(popup = {}) {
    const urlDetails = {
      baseUrl: popup.apiBaseUrl,
      action: FKS_ENDPOINT_EMAIL_VERIFY,
      method: HTTP_METHOD_POST,
    };
    const formOptions = {
      noValidate: true,
    };
    const formProps = {
      popup: popup,
      urlDetails: urlDetails,
      formOptions: formOptions,
    };
    super(formProps);
    this.emailAddressInput = EmailAddressInput(FKP_DISCOUNT_EMAIL_CLASS);
    this.children = this.buildChildren();
  }

  buildChildren() {
    const submit = SubmitButton();

    const DISCOUNT_DISCLAIMER_TEXT =
      'Your discount code is linked to the email you provide and can only be used with that email.';

    const disclaimerWarning = new Notice();
    disclaimerWarning.showWarning(DISCOUNT_DISCLAIMER_TEXT);

    return [disclaimerWarning.html, this.emailAddressInput.html, submit.html];
  }

  beforeSubmit(popupForm) {
    const { formData } = popupForm;
    injectSessionInFormData(this.popup.getSessionUuid(), formData);
    const email = this.emailAddressInput?.getValue();
    this.popup.setEmail(email);
  }

  onSuccess(data, popupForm) {
    this.popup.setStage(STAGES.STAGE_5B);
  }
}
