import IconSuccess from './icon/IconSuccess';
import IconError from './icon/IconError';
import IconWarning from './icon/IconWarning';

const NOTICE_CLASS = 'notice';
const NOTICE_ERROR_CLASS = 'notice--error';
const NOTICE_SUCCESS_CLASS = 'notice--success';
const NOTICE_ICON_CLASS = 'notice__icon';
const NOTICE_MESSAGE_CLASS = 'notice__message';
const NOTICE_WARNING_CLASS = 'notice--warning';

export default class Notice {
  html = null;
  iconContainer = null;
  messageElement = null;
  iconSuccess = null;
  iconError = null;
  props = {};

  constructor(props) {
    this.props = { ...props };
    this.create();
  }

  create = () => {
    this.html = this.createContainer();
    this.iconContainer = this.createIconContainer();
    this.messageElement = this.createMessageContainer();
    this.iconSuccess = IconSuccess();
    this.iconError = IconError();
    this.iconWarning = IconWarning();
    this.html.appendChild(this.iconContainer);
    this.html.appendChild(this.messageElement);
  };

  createContainer = () => {
    const container = document.createElement('div');
    container.classList.add(NOTICE_CLASS);
    const { id = null } = this.props;
    if (id) {
      container.id = id;
    }
    return container;
  };

  createIconContainer = () => {
    const iconContainer = document.createElement('div');
    iconContainer.classList.add(NOTICE_ICON_CLASS);
    return iconContainer;
  };

  createMessageContainer = () => {
    const messageElement = document.createElement('p');
    messageElement.classList.add(NOTICE_MESSAGE_CLASS);
    messageElement.setAttribute('aria-live', 'polite');
    const { text = null } = this.props;
    if (text) {
      messageElement.innerText = text;
    }
    return messageElement;
  };

  setText = (text) => {
    this.messageElement.innerText = text;
  };

  showSuccess = (text) => {
    this.clear();
    this.html.classList.add(NOTICE_SUCCESS_CLASS);
    this.iconContainer.appendChild(this.iconSuccess);
    this.setText(text);
  };

  showError = (text) => {
    this.clear();
    this.html.classList.add(NOTICE_ERROR_CLASS);
    this.iconContainer.appendChild(this.iconError);
    this.setText(text);
  };

  showWarning = (text) => {
    this.clear();
    this.html.classList.add(NOTICE_WARNING_CLASS);
    this.iconContainer.appendChild(this.iconWarning);
    this.setText(text);
  };

  clear = () => {
    this.iconContainer.replaceChildren();
    this.html.classList.remove(NOTICE_ERROR_CLASS, NOTICE_SUCCESS_CLASS);
    this.setText('');
  };
}
