export default class PopupConfig {
  constructor(config = {}) {
    const defaultConfig = {
      popup_configuration: {},
      api_configuration: {
        is_popup_enabled: false,
      },
      offer_claimed: false,
    };

    this.config = { ...defaultConfig, ...config };
  }

  setConfigFromJson(configObj) {
    if (typeof configObj !== 'object' || !configObj) {
      throw new Error('configObj needs to be a valid non-null object');
    }
    Object.assign(this.config, configObj);
  }

  getPopupConfiguration() {
    return this.config.popup_configuration;
  }

  getApiConfiguration() {
    return this.config.api_configuration;
  }

  offerClaimed() {
    return Boolean(this.config.offer_claimed);
  }
}
