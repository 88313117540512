import StageForm from './StageForm.js';
import { injectSessionInFormData } from '../utils.js';
import { ClaimEmailText, SubmitButton } from '../components/form/field/fields.js';
import { FKS_ENDPOINT_CLAIM_DISCOUNT, STAGES } from '../constants.js';
import { HTTP_METHOD_POST } from './stageFormConstants';

const SUBMIT_CLAIM_BUTTON_TEXT = 'Claim Code';

export default class Stage5bForm extends StageForm {
  constructor(popup = {}) {
    const urlDetails = {
      baseUrl: popup.apiBaseUrl,
      action: FKS_ENDPOINT_CLAIM_DISCOUNT,
      method: HTTP_METHOD_POST,
    };
    const formOptions = {
      noValidate: true,
    };
    const formProps = {
      popup: popup,
      urlDetails: urlDetails,
      formOptions: formOptions,
    };
    super(formProps);

    this.emailTextComponent = ClaimEmailText(popup.getEmail());
    this.children = this.buildChildren();
  }

  buildChildren() {
    const submitButton = SubmitButton(SUBMIT_CLAIM_BUTTON_TEXT);
    return [this.emailTextComponent.html, submitButton.html];
  }

  updateEmailText() {
    const email = this.popup?.getEmail?.();
    this.emailTextComponent?.setText?.(email);
  }

  onLoad() {
    this.updateEmailText();
  }

  beforeSubmit(popupForm) {
    const { formData } = popupForm;
    injectSessionInFormData(this.popup.getSessionUuid(), formData);
  }

  onSuccess(data) {
    const { discount_title: discountTitle } = data;
    this.popup?.setDiscountCode?.(discountTitle);
    this.popup?.setCookieLastDisplayed?.();
    this.popup?.setStage?.(STAGES.STAGE_6B);
  }
}
