export const FORM_FIELD_CLASSES = {
  FIELD: 'field',
  ERROR: 'field--error',
  WRAPPER: 'field__input-wrapper',
  WRAPPER_SELECT: 'field__input-wrapper--select',
  INPUT: 'field__input',
  DOUBLE: 'field--double',
};

export const FORM_FIELD_ELEMENT_TYPES = {
  FIELD: 'div',
  WRAPPER: 'div',
  LABEL: 'label',
  INPUT: 'input',
  SELECT: 'select',
};
