const FKP_STAGE_CONTAINER_CLASS = 'fkp__stage-container';
const FKP_STAGE_CONTAINER_HIDDEN_CLASS = `${FKP_STAGE_CONTAINER_CLASS}--hidden`;

export default class PopupContainer {
  html = document.createElement('div');

  constructor(popup, props) {
    this.popup = popup;
    this.props = props;
    this.initializeContainer();
  }

  initializeContainer = () => {
    this.html.classList.add(FKP_STAGE_CONTAINER_CLASS);
    const { children = [], classes = [], hideOnLoad = true } = this.props;

    children.forEach((child) => {
      this.html.appendChild(child.html);
    });
    if (classes) {
      this.html.classList.add(...classes);
    }
    if (hideOnLoad) {
      this.hide();
    }
  };

  show = () => {
    this.props.onShow?.(this);
    const autofocusElement = this.html.querySelector('[autofocus]');
    autofocusElement?.focus();
    this.html.classList.remove(FKP_STAGE_CONTAINER_HIDDEN_CLASS);
  };

  load = async () => {
    await this.props.onLoad?.(this);
  };

  hide = () => {
    this.html.classList.add(FKP_STAGE_CONTAINER_HIDDEN_CLASS);
  };
}
