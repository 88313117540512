import { PopupForm } from '../components/form/PopupForm.js';

/**
 * @property {FKPPopup} popup
 * @property {object} urlDetails
 * @property {object} formOptions
 * @property {HTMLElement[]} children
 */
export default class StageForm {
  constructor(props = {}) {
    const { popup = {}, urlDetails = {}, children = [], formOptions = {} } = props;
    this.popup = popup;
    this.urlDetails = urlDetails;
    this.children = children;
    this.formOptions = formOptions;
  }

  beforeSubmit(popupForm) {}

  onSuccess(data) {}

  onError(error) {
    return this.popup.notifyError(error);
  }

  onShow() {}

  onLoad() {}

  buildPopupFormOptions() {
    return {
      ...this.formOptions,
      ...this.urlDetails,
      children: this.children,
      beforeSubmit: this.beforeSubmit.bind(this),
      onSuccess: this.onSuccess.bind(this),
      onError: this.onError.bind(this),
      onShow: this.onShow.bind(this),
      onLoad: this.onLoad.bind(this),
    };
  }

  buildForm() {
    return new PopupForm(this.buildPopupFormOptions());
  }
}
