import IconUtils from './IconUtils';

export default function IconCopy() {
  const viewBox = '0 -960 960 960';
  const pathData = `
  M180-81q-24 0-42-18t-18-42v-573q0-12.75 8.675-21.375 8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-714v573h444q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5Q654-98 645.375-89.5T624-81H180Zm120-120q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h440q24 0 42 18t18 42v560q0 24-18 42t-42 18H300Zm0-60h440v-560H300v560Zm0 0v-560 560Z
    `;

  return IconUtils.buildSvg(pathData, viewBox);
}
