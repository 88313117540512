import StageForm from './StageForm.js';
import { injectSessionInFormData } from '../utils.js';
import { OneTimeCodeInput, SubmitButton } from '../components/form/field/fields.js';
import SMSVerificationLink from '../components/form/field/link/SMSVerificationLink.js';
import { FKS_ENDPOINT_SMS_VERIFY, STAGES } from '../constants.js';
import { HTTP_METHOD_POST } from './stageFormConstants';

export default class Stage2Form extends StageForm {
  static SUBMIT_BUTTON_TEXT = 'Verify';
  constructor(popup = {}) {
    const urlDetails = {
      baseUrl: popup.apiBaseUrl,
      action: FKS_ENDPOINT_SMS_VERIFY,
      method: HTTP_METHOD_POST,
    };
    const formOptions = {
      noValidate: true,
    };
    const formProps = {
      popup: popup,
      urlDetails: urlDetails,
      formOptions: formOptions,
    };
    super(formProps);
    this.resendCodeLink = this.createResendCodeLink(popup);
    this.children = this.buildChildren();
  }

  buildChildren() {
    const oneTimeCodeInput = OneTimeCodeInput();
    oneTimeCodeInput.wrapper.after(this.resendCodeLink?.html);
    const submitButton = SubmitButton(Stage2Form.SUBMIT_BUTTON_TEXT);

    this.resendCodeLink.html.addEventListener('click',
      () => this.handleResendLinkClick(oneTimeCodeInput)
    );

    return [oneTimeCodeInput.html, submitButton.html];
  }

  createResendCodeLink(popup) {
    return new SMSVerificationLink({
      onClick: () => {
        popup?.submitStageForm?.(STAGES.STAGE_1);
      },
    });
  }

  handleResendLinkClick(oneTimeCodeInput) {
    const inputs = oneTimeCodeInput?.html?.querySelectorAll('input');
    if (!inputs?.length) {
      return;
    }
    inputs.forEach((input) => {
      input.value = '';
    });
  }

  onLoad() {
    this.resendCodeLink.start();
  }

  beforeSubmit(popupForm) {
    const { formData } = popupForm;
    injectSessionInFormData(this.popup.getSessionUuid(), formData);
  }

  onSuccess() {
    this.popup?.setStage?.(STAGES.STAGE_3);
  }
}
