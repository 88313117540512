import { STAGES } from '../constants';

/**
 * @property {PopupForm} form
 * @property {PopupContainer} container
 * @property {typeof StageForm} formConstructor
 */
export default class Stage {
  constructor({ popup, stageName, formConstructor, hideOnLoad = true }) {
    this.popup = popup;
    this.stageName = stageName;
    this.formConstructor = formConstructor;
    this.hideOnLoad = hideOnLoad;
    this.form = null;
    this.container = null;
  }

  buildForm() {
    if (!this.isConstructor(this.formConstructor)) {
      throw new Error('formConstructor is not a constructor');
    }
    const stageFormInstance = new this.formConstructor(this.popup);
    const form = stageFormInstance.buildForm();
    if (!form) {
      throw new Error(`${this.stageName} Error: Form not initialized`);
    }
    return form;
  }

  isConstructor(potentialConstructor) {
    if (!potentialConstructor) {
      return false;
    }

    const isFunction = typeof potentialConstructor === 'function';
    const hasPrototype = Boolean(potentialConstructor?.prototype);
    const isValid = potentialConstructor?.prototype?.constructor === potentialConstructor;
    return isFunction && hasPrototype && isValid;
  }

  buildStageContainer(form) {
    throw new Error('Method "buildStageContainer" is not implemented');
  }

  buildChildren(form) {
    throw new Error('Method "buildChildren" is not implemented');
  }

  getStageTitleAndDescription() {
    const stageConfig = this.popup?.getStageConfiguration?.(STAGES[this.stageName]) ?? {};
    const { title = '', description = '' } = stageConfig;
    return { title, description };
  }

  insertNotificationInForm = (notificationElement) => {
    if (!this.form) {
      console.error('form not initialized');
      return;
    }

    if (!notificationElement) {
      console.error('notificationElement must be valid dom element');
      return;
    }

    this.form.insertElementBeforeForm(notificationElement);
  };

  getStageHTML() {
    return this.container?.html;
  }

  load = async () => {
    await this.container.load();
  };

  show = () => {
    this.container.show();
  };

  hide = () => {
    this.container.hide();
  };

  submitForm = () => {
    const formElement = this.form.html;
    formElement.dispatchEvent(new SubmitEvent('submit'));
    formElement.requestSubmit();
  };
}
